@use '../mixins/responsive';

.footer {
  background-color: var(--black-100);
}

.footer-top, .footer-icon, .footer-middle-text, .footer-share-container {
  display: flex;
  align-items: flex-start;
}

.footer-top {
  justify-content: space-between;
  padding-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @include responsive.media-breakpoint-down(xl) {
    padding-top: 32px;
  }

  @include responsive.media-breakpoint-down(sm) {
    padding-top: 18px;
    border-bottom: unset;
  }

  @include responsive.media-breakpoint-down(smxs) {
    flex-direction: column;
  }
}

.footer-icon {
  gap: 16px;
  max-width: 245px;
  align-items: flex-start;

  @include responsive.media-breakpoint-down(xl) {
    max-width: 243px;
    gap: 5px;
  }
}

.footer_logo-img {
  width: max-content
}

.footer-logo-text {
  color: var(--grey-100);
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;

  @include responsive.media-breakpoint-down(xl) {
    font-size: 14px;
    line-height: 19.6px;
  }

  @include responsive.media-breakpoint-down(sm) {
    display: none;
  }
}

.footer-middle-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
  color: var(--white-100);
  gap: 32px;
  padding-top: 13px;

  @include responsive.media-breakpoint-down(xl) {
    gap: 24px;
    font-size: 14px;
    line-height: 19.6px;
  }
  @include responsive.media-breakpoint-down(lg) {
    display: none;
  }

  .footer-item {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--white-100);
    transition: 0.3s;
    @include responsive.media-breakpoint-down(xl) {
      gap: 4px;
    }

    &:hover {
      color: #FFFFFF99;
    }

    &:before {
      display: inline-block;
      width: 20px;
      height: 20px;

      @include responsive.media-breakpoint-down(xl) {
        width: 16px;
        height: 16px;
      }
    }
  }

  @include responsive.media-breakpoint-down(lg) {
    display: none;
  }
}

.footer-share-container {
  gap: 8px;
  padding-top: 6px;
}

.footer-middle-text, .footer-share-container {
  z-index: 3;
  position: sticky;
}

.footer-share-item {
  transition: all .3s;

  @media (any-hover: hover) {
    &:not(.whatsapp, .youtube) {
      &:hover path {
        fill: var(--green-100);
      }
    }
    &:not(.teleg, .vk) {
      &:hover path:nth-child(1) {
        fill: var(--green-100);
      }
    }
    &:not(.teleg, .vk, .whatsapp) {
      &:hover path:nth-child(2) {
        fill: var(--green-100);
      }
    }
  }

}
