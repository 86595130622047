$transition-default: 0.3s ease-in-out;

:root {
  --grey-10: #E7EDF3;
  --black-100: #0E0F11;
  --green-100: #28906F;
  --Card-Background: #F3F7FB;
  --white-100: #ffffff;
  --grey-100: #8F959B;
  --grey-50: rgba(195, 200, 207, 1);
  --green-10: #DBF5ED;
  --red-100: #F24C41;
  --white-50: rgba(255, 255, 255, 0.6);
  --white-10: rgba(255, 255, 255, 0.1);
  --blue-100: rgba(37, 87, 246, 1);
  --blue-20: rgba(21, 115, 255, 0.20);
  --red-20: rgba(240, 87, 77, 0.2);
  --red-100: rgba(242, 76, 65, 1)
}
