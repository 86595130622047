@use '../mixins/responsive';

@keyframes horizontalAnim {
  0% {
    transform: translateX(0)
  }

  20% {
    transform: translateX(40px)
  }

  100% {
    transform: translateX(60px)
  }
}

@keyframes verticalAnim {
  0% {
    transform: translateY(0px) scale(1) rotate(0);
  }

  80% {
    transform: translateY(-105px) scale(.58) rotate(180deg);
  }

  100% {
    transform: translateY(-135px) scale(.1) rotate(270deg);
  }
}

@keyframes verticalAnimAsist {
  0% {
    transform: translateY(0px) scale(1) rotate(0);
  }

  80% {
    transform: translateY(-70px) scale(.58) rotate(180deg);
  }

  100% {
    transform: translateY(-105px) scale(.1) rotate(270deg);
  }
}

.main-banner_discount {
  &_container {
    top: 265px;
    right: 0;
    position: absolute;
    width: max-content;
    height: max-content;
    z-index: 9;
    @include responsive.media-breakpoint-down(exxl) {
      top: 200px;
    }

    @include responsive.media-breakpoint-down(elg) {
      top: 32px;
    }

    .percent-left {
      display: block;
      left: 0;
      //animation: horizontalAnim 2s infinite both cubic-bezier(0, 0, .63, .9);
      transform-origin: 0 0;
      z-index: 1;

      &:nth-child(2) {
        margin-left: 11px;
        animation-delay: 0.5s;

        &:before {
          animation-delay: 0.5s;
        }
      }

      &:nth-child(3) {
        margin-left: 11px;
        animation-delay: 1.0s;

        &:before {
          animation-delay: 1.0s;
        }
      }

      &:nth-child(4) {
        margin-left: 11px;
        animation-delay: 1.5s;

        &:before {
          animation-delay: 1.5s;
        }
      }
    }

    .percent-left:before {
      left: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M10.9501 7.26621C11.5351 7.65876 11.8881 8.19771 12.0089 8.88304C12.1297 9.56838 11.9824 10.1955 11.567 10.7645C11.1603 11.3214 10.5845 11.6655 9.83947 11.7968C9.0838 11.9301 8.41968 11.8046 7.84712 11.4204C7.26211 11.0278 6.90918 10.4889 6.78834 9.80357C6.6693 9.12846 6.81751 8.50643 7.23298 7.93746C7.64845 7.3685 8.2287 7.01833 8.97373 6.88696C9.71876 6.75559 10.3776 6.88201 10.9501 7.26621ZM9.58543 10.4494C9.92601 10.3894 10.1847 10.2383 10.3615 9.99618C10.5365 9.74384 10.596 9.45912 10.5401 9.14203C10.4842 8.82493 10.3309 8.57775 10.0801 8.40048C9.82939 8.2232 9.53373 8.16459 9.19314 8.22465C8.86319 8.28283 8.61073 8.43809 8.43574 8.69043C8.26075 8.94277 8.20121 9.22749 8.25712 9.54458C8.31123 9.85145 8.46366 10.0935 8.7144 10.2708C8.97578 10.4462 9.26613 10.5057 9.58543 10.4494ZM9.89641 16.881L8.94773 16.542L14.9197 7.37311L15.8657 7.69674L9.89641 16.881ZM16.044 17.1101C15.2883 17.2433 14.6242 17.1178 14.0516 16.7336C13.4666 16.3411 13.1137 15.8021 12.9928 15.1168C12.8738 14.4417 13.022 13.8197 13.4375 13.2507C13.853 12.6817 14.4332 12.3316 15.1782 12.2002C15.9126 12.0707 16.5723 12.2022 17.1573 12.5948C17.7423 12.9873 18.0944 13.5212 18.2134 14.1963C18.3342 14.8816 18.1869 15.5088 17.7715 16.0777C17.3648 16.6346 16.789 16.9787 16.044 17.1101ZM15.7899 15.7627C16.1305 15.7026 16.3892 15.5515 16.566 15.3094C16.741 15.0571 16.8005 14.7724 16.7446 14.4553C16.6887 14.1382 16.5354 13.891 16.2846 13.7137C16.0339 13.5364 15.7382 13.4778 15.3976 13.5379C15.0677 13.5961 14.8152 13.7513 14.6402 14.0037C14.4653 14.256 14.4057 14.5407 14.4616 14.8578C14.5157 15.1647 14.6682 15.4068 14.9189 15.584C15.1803 15.7594 15.4706 15.819 15.7899 15.7627Z' fill='%23F24C41'/%3E%3C/svg%3E");
      //animation: verticalAnim 2s infinite both cubic-bezier(.21, .01, .2, .96);
      animation: verticalAnim 2s infinite both cubic-bezier(0.41, 0.48, 0.43, 0.45);;
      transform-origin: 0 0;
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      top: 0;
    }

    .percent-circle {
      display: flex;
      gap: 25px;
      flex-direction: column;
      position: absolute;
      z-index: -1;
      right: 48px;
      bottom: -170px;

      &_right {
        display: flex;
        gap: 25px;
        flex-direction: column;
        position: absolute;
        z-index: -1;
        right: 20px;
        bottom: -100px;

        .percent-left {
          &:nth-child(2) {
            margin-left: 11px;
            animation-delay: 0.5s;

            &:before {
              animation-delay: 0.5s;
            }
          }
        }

        .percent-left:before {
          animation: verticalAnimAsist 1s infinite both cubic-bezier(0.41, 0.48, 0.43, 0.45);;
        }
      }
    }
  }

  &-btn {
    border-radius: 12px 12px 0 0;
    padding: 10px 16px;
    background: var(--red-100, #F24C41);
    color: var(--white-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    transform: rotate(-90deg) translateX(-40%) translateY(202%);
    width: max-content;
    @include responsive.media-breakpoint-down(smd) {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
