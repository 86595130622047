@use '../mixins/responsive';

.bankruptcy {
  position: relative;

  &.open {
    &:after {
      opacity: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 212px;
    pointer-events: none;
    background: linear-gradient(rgba(243, 247, 251, 0), rgba(243, 247, 251, 1));
  }

  h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;

    @include responsive.media-breakpoint-down(xl) {
      font-size: 32px;
      line-height: 38.4px;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    @include responsive.media-breakpoint-down(xl) {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  &-wrapper {
    display: flex;
    gap: 24px;
    margin-top: 32px;
    max-height: 8em;
    overflow: hidden;
    position: relative;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 20px;
      max-height: 7em;
    }

    @include responsive.media-breakpoint-down(lg) {
      flex-direction: column;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 16px;
      max-height: 9em;
    }

    &.open {
      max-height: none;
      overflow: visible;
      padding-bottom: 50px;
    }
  }

  .bankruptcy-gradient {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 32px;
    background: linear-gradient(rgba(243, 247, 251, 0), rgba(243, 247, 251, 1));
  }

  .padding-top-bottom {
    padding-top: 40px;
    padding-bottom: 80px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 24px;
      padding-bottom: 60px;
      padding-top: 0;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 32px;
      padding-bottom: 72px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding-bottom: 40px;
    }
  }
}

.button-see-more {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 8px;
  height: 32px;
  box-shadow: 1px 8px 20px 0 rgba(12, 13, 16, 0.05);
  background-color: var(--white-100);
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  align-items: center;
  transition: color .3s;

  @media (any-hover: hover) {
    &:hover {
      color: var(--green-100);
    }
  }
}
