@use '../mixins/responsive';

.services-list {
  padding: 32px 0 12px;
  position: relative;

  &_wrapper.page-container {
    position: relative;
    @include responsive.media-breakpoint-down(xl) {
      padding-right: 0;
    }
  }

  &_item {
    padding: 32px;
    border-radius: 24px;
    background: var(--white-100);
    min-height: 242px;
    max-width: 321px;
    @include responsive.media-breakpoint-down(xl) {
      padding: 24px;
      max-width: 231px;
    }
    @include responsive.media-breakpoint-down(elg) {
      max-width: 268px;
    }
    @include responsive.media-breakpoint-down(sm) {
      max-width: 240px;
    }

    &:last-child {
      @include responsive.media-breakpoint-down(xl) {
        margin-right: 32px;
      }
      @include responsive.media-breakpoint-down(smd) {
        margin-right: 16px;
      }
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      @include responsive.media-breakpoint-down(xl) {
        margin-bottom: 40px;
      }
      @include responsive.media-breakpoint-down(sm) {
        margin-bottom: 32px;
      }
    }

    &-img {
      width: 64px;
      height: 64px;
      flex-grow: 0;
      @include responsive.media-breakpoint-down(xl) {
        width: 64px;
        height: 64px;
      }
      @include responsive.media-breakpoint-down(sm) {
        width: 48px;
        height: 48px;
      }
    }

    &-button {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 1000px;
      background: var(--Card-Background);
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: var(--grey-10);
      }
    }

    &-title {
      color: var(--black-100);
      font-feature-settings: 'ss12' on;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      margin-bottom: 12px;
      @include responsive.media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

    &-time {
      color: var(--grey-100);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      display: flex;
      align-items: center;
      @include responsive.media-breakpoint-down(xl) {
        font-size: 14px;
      }

      &:before {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
  }

  .swiper-scrollbar {
    display: none;

    @include responsive.media-breakpoint-down(xl) {
      display: block;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color: var(--white-100);
      border-radius: 10px;
      position: unset !important;
      margin-top: 32px;
    }
    @include responsive.media-breakpoint-down(smd) {
      margin-top: 16px;
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--green-100);
    border-radius: 10px;
  }


  .arrows-right-left {
    position: absolute;
    right: 41px;
    bottom: 40%;
    z-index: 2;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .arrow {
      background-color: var(--green-100);
      box-shadow: 1px 8px 20px 0 rgba(12, 13, 16, 0.08);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      transition: 0.3s;
      @include responsive.media-breakpoint-down(xl) {
        display: none;
      }

      &:hover {
        background-color: #1A8362;
      }
    }

    .arrow-right {
      transform: rotate(180deg);
    }
  }
}
