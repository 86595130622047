@use '../mixins/responsive';

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  &-bg {
    position: absolute;
    z-index: 1;
    background: rgba(14, 15, 17, 0.60);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.3s;
    opacity: 0;
  }

  &-container {
    transition: 1s;
    position: relative;
  }

  &-left {
    .modal-container {
      transform: translateX(-120%);
    }

    .modal-close {
      top: 12px;
      left: calc(100% + 12px);
      @include responsive.media-breakpoint-down(smxs) {
        top: 0;
        left: unset;
        right: 0;
        path {
          fill: var(--black-100);
        }
      }
    }

    &.show {
      .modal-container {
        transform: translateX(0);
      }
    }
  }

  &-midle {
    &.show {
      .modal-container {
        opacity: 1;
      }
    }

    .modal-wrapper {
      padding: var(--modal-wrapper-padding, 30px);
      z-index: 1;
      height: 100%;
      overflow: hidden auto;
      display: flex;
      align-items: center;
      max-width: var(--modal-wrapper-width, 920px);
      width: 100%;
      margin: auto;
      scrollbar-width: none;

      @include responsive.media-breakpoint-down(elg) {
        max-width: var(--modal-wrapper-width, 100%);
        padding: var(--modal-wrapper-padding, 100px 0 0 0);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .modal-container {
      opacity: 0;
      transition: 0.1s;

      z-index: 2;
      width: 100%;
      max-width: var(--modal-container-width, 860px);
      min-height: var(--modal-midle-min-height, 520px);
      border-radius: 24px;
      background: var(--white-100);
      padding: var(--modal-midle-padding, 12px);
      margin: auto;
      @include responsive.media-breakpoint-down(elg) {
        max-width: 100%;
        border-radius: 32px 32px 0px 0px;
        margin: auto 0 0 0;
      }
      @include responsive.media-breakpoint-down(lg) {
        padding:var(--modal-midle-padding, 32px);
      }
      @include responsive.media-breakpoint-down(sm) {
        padding: var(--modal-midle-padding, 28px 16px);
      }
    }

    .modal-close {
      left: 100%;
      bottom: 100%;
      @include responsive.media-breakpoint-down(elg) {
        left: auto;
        right: 16px;
        bottom: calc(100% + 12px);
      }
    }
  }

  &-bottom {
    z-index: 99999;

    .modal-wrapper {
      padding: 30px 0 0 0;
      z-index: 1;
      height: 100%;
      overflow: hidden auto;
      width: 100%;
      margin: auto;
      scrollbar-width: none;
      display: flex;
      align-items: flex-end;
      @include responsive.media-breakpoint-down(elg) {
        padding: 50px 0 0 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .modal-container {
      z-index: 1;
      opacity: 1;
      transform: translateY(120%);
      border-radius: 32px 32px 0px 0px;
      background: var(--white-100, #FFF);
      backdrop-filter: blur(25px);
      padding: 32px 32px 40px 32px;
      width: 100%;
      margin: auto 0 0 0;
      transition: 0.5s;
      @include responsive.media-breakpoint-down(smd) {
        padding: 25px 20px 32px;
      }
    }

    &.show {
      .modal-container {
        transition: 0.5s;
        transform: translateY(0);
      }
    }

    .modal-close {
      top: 32px;
      right: 32px;
      left: auto;
      bottom: auto;
      border-radius: 10px;
      background: var(--grey-10, #E7EDF3);
      padding: 8px;
      width: 32px;
      height: 32px;
      z-index: 1;

      @include responsive.media-breakpoint-down(smd) {
        background: transparent;
        top: -36px;
        right: 16px;
        padding: 0;
        width: 24px;
        height: 24px;
      }

      &:hover {
        svg {
          path {
            fill: var(--green-100);
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
        @include responsive.media-breakpoint-down(smd) {
          width: 24px;
          height: 24px;
        }

        path {
          transition: 0.3s;
          @include responsive.media-breakpoint-down(smd) {
            fill: var(--white-100);
            opacity: 0.6;
          }
        }
      }
    }
  }

  &.show {
    pointer-events: auto;
    z-index: 99999;

    .modal-bg {
      opacity: 1;
    }
  }

  &-close {
    position: absolute;

    width: 32px;
    height: 32px;
    @include responsive.media-breakpoint-down(elg) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 32px;
      height: 32px;
      @include responsive.media-breakpoint-down(elg) {
        width: 24px;
        height: 24px;
      }
    }
  }
}
