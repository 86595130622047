@use '../mixins/responsive';

.main-banner {
  background-color: var(--white-100);
  &_container {
    display: flex;
    justify-content: flex-end;
    @include responsive.media-breakpoint-down(elg) {
      flex-direction: column;
    }
  }

  &_left {
    z-index: 9;

    display: flex;
    flex-direction: column;

    width: 660px;
    padding: 40px 118px 40px 0;
    @include responsive.media-breakpoint-down(xxl) {
      width: 50%;
      padding: 40px 32px;
    }
    @include responsive.media-breakpoint-down(elg) {
      width: 100%;
      align-items: center;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding: 32px 16px;
    }
  }

  &_title {
    margin-bottom: 24px;
    @include responsive.media-breakpoint-down(elg) {
      text-align: center;
      max-width: 680px;
      margin-bottom: 32px;
    }
    @include responsive.media-breakpoint-down(smd) {
      margin-bottom: 16px;
    }

    &__sub {
      display: inline-flex;
      padding: 6px 14px 6px 8px;
      border-radius: 1000px;
      background: var(--Card-Background);
      margin-bottom: 16px;

      color: var(--grey-100, #8F959B);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      @include responsive.media-breakpoint-down(xl) {
        margin-bottom: 20px;
      }

      @include responsive.media-breakpoint-down(elg) {
      }

      @include responsive.media-breakpoint-down(smd) {
        margin-bottom: 16px;
        font-size: 12px;
        padding: 5.5px 12px 5.5px 8px;
      }

      &:before {
        width: 18px;
        height: 18px;
        margin-right: 2px;
        @include responsive.media-breakpoint-down(smd) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &_desc {
    display: inline-flex;
    align-items: center;
    margin-bottom: 40px;

    color: var(--black-100);
    font-feature-settings: 'ss12' on;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    @include responsive.media-breakpoint-down(xl) {
      font-size: 16px;
      margin-bottom: 32px;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding: 0 51.5px;
      text-align: center;
      display: inline;
      margin-bottom: 24px;
    }

    &:before {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      @include responsive.media-breakpoint-down(smd) {
        content: unset;
      }
    }

    span {
      color: var(--green-100);
      margin-left: 5px;
    }
  }

  &_button-consultation {
    width: max-content;
    padding: 20.5px 32px;
    @include responsive.media-breakpoint-down(md) {
      padding: 20.5px 24px;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding: 14px 24px;
    }
  }

  &_achievements {
    display: flex;
    margin-top: auto;
    gap: 20px;
    @include responsive.media-breakpoint-down(xxl) {
      gap: 10px;
    }
    @include responsive.media-breakpoint-down(elg) {
      margin-top: 32px;
      gap: 20px;
    }
    @include responsive.media-breakpoint-down(smd) {
      display: none;
    }

    &__delimiter {
      margin: 5px 0;
      width: 1px;
      background: #E7EDF3;
      @include responsive.media-breakpoint-down(xxl) {
        margin-right: 6px;
      }
      @include responsive.media-breakpoint-down(elg) {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  &_achievement {
    @include responsive.media-breakpoint-down(elg) {
      text-align: center;
    }

    &__number {
      position: relative;
      color: var(--black-100);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 33.6px */
      margin-bottom: 4px;
      display: flex;
      align-items: flex-start;
      gap: 6px;

      @include responsive.media-breakpoint-down(xxl) {
        font-size: 24px;
        margin-bottom: 2px;
      }
      @include responsive.media-breakpoint-down(elg) {
        display: inline-block;
      }

      &:after {
        position: relative;
        top: 0;
        width: 16px;
        height: 16px;
        font-size: 0;
        @include responsive.media-breakpoint-down(elg) {
          position: absolute;
          left: calc(100% + 6px);
        }
      }
    }

    &__desc {
      color: var(--grey-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }

  &_right {
    background: var(--green-100);
    width: 50.52%;
    position: relative;
    height: 732px;
    @include responsive.media-breakpoint-down(xxl) {
      width: 50%;
    }
    @include responsive.media-breakpoint-down(xl) {
      height: 660px;
    }
    @include responsive.media-breakpoint-down(elg) {
      width: 100%;
      height: 480px;
      overflow: hidden;
    }
    @include responsive.media-breakpoint-down(smd) {
      height: 444px;
    }

    &_container {
      max-width: 1024px;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &_bg {
      position: absolute;
      width: 100%;
      height: 100%;

      &__boss {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 493px;
        @include responsive.media-breakpoint-down(xl) {
          width: 448px;
        }
        @include responsive.media-breakpoint-down(elg) {
          width: 400px;
          bottom: auto;
          top: 20px;
        }
        @include responsive.media-breakpoint-down(smd) {
          width: 327px;
          padding-left: 23px;
        }
      }

      &__arrow {
        position: absolute;
        top: 208px;
        left: -176px;
        @include responsive.media-breakpoint-down(xl) {
          top: 178px;
          width: 321px;
          height: 300px;
          left: -163px;
        }
        @include responsive.media-breakpoint-down(elg) {
          display: none;
        }
      }
    }

    &_label {
      margin: 12px;
      display: flex;
      justify-content: space-between;
      border-radius: 20px;
      background: var(--black-20, rgba(19, 23, 21, 0.30));
      backdrop-filter: blur(18px);
      padding: 32px;
      @include responsive.media-breakpoint-down(xl) {
        flex-direction: column;
        gap: 24px;
        padding: 24px;
      }
      @include responsive.media-breakpoint-down(elg) {
        flex-direction: row;
        margin: 20px;
      }
      @include responsive.media-breakpoint-down(smd) {
        flex-direction: column;
        padding: 16px;
        margin: 16px;
      }
    }

    &_desc {
      max-width: 360px;
      color: var(--white-50);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      @include responsive.media-breakpoint-down(xl) {
        max-width: 100%;
        font-size: 20px;
      }
      @include responsive.media-breakpoint-down(elg) {
        max-width: 419px;
        font-size: 24px;
      }
      @include responsive.media-breakpoint-down(smd) {
        max-width: 100%;
        font-size: 16px;
      }

      span {
        color: var(--white-100);
        font-weight: 700;
      }
    }

    &_worker {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include responsive.media-breakpoint-down(xl) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      @include responsive.media-breakpoint-down(elg) {
        flex-direction: column;
        align-items: flex-end;
      }
      @include responsive.media-breakpoint-down(smd) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &__ico {
        display: flex;
        margin-bottom: 17px;
        @include responsive.media-breakpoint-down(xl) {
          margin-bottom: 0;
        }

        &-img {
          width: 40px;
          height: 40px;
          border-radius: 1000px;
          background: var(--white-100);
        }

        &-quotes {
          margin-left: -10px;
        }
      }

      &__name {
        color: var(--white-100);
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4px;
        @include responsive.media-breakpoint-down(smd) {
          font-size: 14px;
        }
      }

      &__position {
        color: var(--white-50);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @include responsive.media-breakpoint-down(smd) {
          font-size: 12px;
        }
      }
    }
  }

  &_present {
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include responsive.media-breakpoint-down(xl) {
      top: 24px;
      left: 24px;
    }
    @include responsive.media-breakpoint-down(elg) {
      top: 32px;
      left: 32px;
    }
    @include responsive.media-breakpoint-down(smd) {
      top: 20px;
      left: 20px;
    }

    &_subtitle {
      color: var(--white-50);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 2px;
    }

    &_title {
      color: var(--white-100);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 8px;
    }

    &_btn {
      margin-top: -10px;
      color: var(--red-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding: 5px 16px;
      border-radius: 1000px;
      background: var(--white-100);
      box-shadow: 0px 6px 32px 0px rgba(18, 56, 40, 0.10);
    }

    &_img {
      @include responsive.media-breakpoint-down(xl) {
        width: 66px;
        height: 70px;
      }
    }
  }

  &_form {
    @include responsive.media-breakpoint-down(elg) {
      max-width: 560px;
      width: 100%;
    }
    &_inputs-container {
      margin-bottom: 4px;
      display: flex;
      gap: 12px;
      @include responsive.media-breakpoint-down(sm) {
        flex-direction: column;
        margin-bottom: 12px;
      }
    }
    &-input {
      width: 100%;
    }
  }
}
