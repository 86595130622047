@use '../mixins/responsive';

.current-rates {
  display: flex;
  align-items: center;
  flex-direction: column;

  .this-swiper {
    width: 100%;
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  .page-title__main {
    text-align: center;
  }

  .page-container-right-left {
    padding-top: 48px;
    padding-bottom: 48px;

    @include responsive.media-breakpoint-down(xl) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      max-width: 680px;
      width: 100%;
      //padding-left: 0;
      //padding-right: 0;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .page-title__grey {
    text-align: center;
    margin-bottom: 24px;

    @include responsive.media-breakpoint-down(xl) {
      margin-bottom: 16px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-bottom: 12px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;

    @include responsive.media-breakpoint-down(lg) {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }

    &.wrapper-100 {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .bg-gradient {
    margin-top: 48px;
    //width: calc(100%/3);
    border-radius: 24px;
    padding: 4px;

    &.gold {
      background: linear-gradient(rgba(224, 179, 48, 1), rgba(251, 238, 136, 1), rgba(224, 179, 48, 1));
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 0;
      max-width: 680px;
      width: 100%;

      &:nth-child(2), &.gold, &.current-rates-swiper {
        margin-top: 32px;
      }

      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: -1;
      }
    }

    @include responsive.media-breakpoint-down(sm) {
      &:nth-child(2) {
        margin-top: 32px;
      }

      max-width: 100%;
    }
  }

  &__item {
    background-color: var(--white-100);
    border-radius: 21px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 28px 8px;
    min-height: 769px;
    position: relative;

    @include responsive.media-breakpoint-down(xl) {
      padding: 20px 8px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 8px 8px 8px 20px;
      align-items: unset;
      flex-direction: row;
      min-height: 333px;

      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: -1;
      }
    }

    @include responsive.media-breakpoint-down(sm) {
      border-radius: 20px;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      min-height: 487px;
    }
  }

  .item__for-top-text {
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    height: 32px;
    background: linear-gradient(rgba(255, 242, 137, 1), rgba(210, 164, 29, 1));
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-100);
    font-size: 14px;
    font-weight: 600;
    line-height: 1;

    @include responsive.media-breakpoint-down(lg) {
      left: 81px;
    }

    @include responsive.media-breakpoint-down(sm) {
      left: 50%;
      transform: translateX(-50%);
      padding: 0 10px;
      font-size: 12px;
      height: 26px;
      top: -26px;
    }
  }

  .item__top-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include responsive.media-breakpoint-down(lg) {
      align-items: flex-start;
      margin-right: 40px;
      max-width: 303px;
      width: 100%;
      padding-bottom: 24px;
      padding-top: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      align-items: center;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .item__top-title {
    font-weight: 600;
    line-height: 1.2;
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 8px;

    @include responsive.media-breakpoint-down(xl) {
      font-size: 24px;
      margin-top: 16px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 20px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }

  .item__top-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: var(--grey-100);
    text-align: center;

    @include responsive.media-breakpoint-down(mxl) {
      height: 38px;
    }
    @include responsive.media-breakpoint-down(xl) {
      font-size: 14px;
      height: auto;
    }

    @include responsive.media-breakpoint-down(elg) {
      height: 34px;
    }
    @include responsive.media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-bottom: 0;
      color: #111215;
    }
  }

  .item__middle-submiddle-container {
    display: flex;
    flex-direction: column;

    @include responsive.media-breakpoint-down(lg) {
      max-width: 301px;
      width: 100%;
      justify-content: space-between;
    }

    @include responsive.media-breakpoint-down(sm) {
      max-width: 100%;
      justify-content: center;
    }
  }

  .item__middle-container {
    background-color: var(--Card-Background);
    padding: 32px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    .elem-container, .button {
      z-index: 1;
    }

    svg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
    }

    &.--gold {
      background: linear-gradient(rgba(224, 179, 48, 1), rgba(251, 238, 136, 1), rgba(224, 179, 48, 1));
    }

    @include responsive.media-breakpoint-down(xl) {
      padding: 24px 16px 16px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 16px;
      margin: unset;
      max-width: 301px;
      width: 100%;
      justify-content: flex-end;

      &:nth-child(1) {
        height: 100%;
      }
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 20px 16px 16px;
      max-width: 100%;
      width: 100%;
      height: auto;
      margin-top: 16px;
      border-radius: 16px;
      justify-content: center;
    }
  }

  .item__sub-middle-container {
    padding: 12.5px 16px;
    border-radius: 16px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;

    @include responsive.media-breakpoint-down(sm) {
      border-radius: 12px;
      margin-top: 4px;
    }

    .bg {
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none;
    }

    &.--gold {
      background: linear-gradient(rgba(224, 179, 48, 1), rgba(251, 238, 136, 1), rgba(224, 179, 48, 1));
    }

    &.--blue {
      background-color: var(--blue-100);

      .bg {
        opacity: 0.4;
      }
    }

    .repaid {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;

      @include responsive.media-breakpoint-down(xl) {
        left: -8px;
        top: -1px;
      }

      @include responsive.media-breakpoint-down(lg) {
        left: -2px;
        top: -12px;
      }

      @include responsive.media-breakpoint-down(sm) {
        left: 0;
        top: 0px;
      }

      @include responsive.media-breakpoint-down(smxs) {
        display: none;
      }
    }

    &-releative {
      position: relative;
    }
  }

  .sub-middle-container__elemenets {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white-100);
    max-width: 179px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    z-index: 2;
    @include responsive.media-breakpoint-down(xl) {
      font-size: 20px;
      max-width: 160px;
    }

    @include responsive.media-breakpoint-down(lg) {
      gap: 10px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 17.21px;
      max-width: 145px;
    }
  }

  .question-icon {
    flex-shrink: 0;
    z-index: 1;
    cursor: pointer;
    transition: all .3s;
  }

  .hidden-visible-content {
    display: none;
    position: absolute;
    bottom: -73px;
    left: 105px;
    font-size: 14px;
    max-width: 302px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: var(--black-100);
    color: var(--white-100);
    z-index: 1;

    @include responsive.media-breakpoint-down(xl) {
      bottom: -125px;
    }

    @include responsive.media-breakpoint-down(lg) {
      bottom: 50px;
      //svg {
      //  display: none;
      //}
    }

    @include responsive.media-breakpoint-down(md) {
      left: auto;
    }

    @include responsive.media-breakpoint-down(sm) {
      bottom: calc(-100% - 13px);
      right: 0;

      //svg {
      //  right: 17px;
      //  display: block;
      //}
    }

    @include responsive.media-breakpoint-down(smxs) {
      bottom: calc(-100% - 13px);
    }

    svg {
      position: absolute;
      top: -9px;
      right: 12px;
      @include responsive.media-breakpoint-down(xl) {
        top: auto;
        bottom: -9px;
        transform: rotate(180deg);
        right: 17px;
      }
      @include responsive.media-breakpoint-down(sm) {
        bottom: auto;
        top: -9px;
        transform: rotate(0);
      }
    }

  }

  .elem-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .item__middle-title {
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px;
    margin-bottom: 8px;

    &.white {
      color: var(--white-100);
    }

    @include responsive.media-breakpoint-down(xl) {
      font-size: 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 24px;
      margin-bottom: 4px;
    }
  }

  .item__middle-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: var(--grey-100);
    margin-bottom: 24px;

    &.white {
      color: var(--white-100);
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-bottom: 77.5px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  .item__down-container {
    padding: 0 24px;
    margin-top: 24px;

    &.mob {
      display: none;
    }

    @include responsive.media-breakpoint-down(xl) {
      padding: 0 16px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 0;

      &.desc {
        display: none;
      }

      &.mob {
        display: block;
        padding: 0;
      }
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 0 10px 0 0;
      margin-top: 16px;

      &.desc {
        display: block;
      }

      &.mob {
        display: none;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include responsive.media-breakpoint-down(xl) {
        gap: 8px;
      }

      li {
        display: inline;
        align-items: flex-start;
        //gap: 6px;
        padding-left: 26px;
        position: relative;

        color: var(--black-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */

        @include responsive.media-breakpoint-down(xl) {
          font-size: 14px;
        }
        @include responsive.media-breakpoint-down(sm) {
          //gap: 2px;
          padding-left: 18px;
        }

        &::before {
          width: 20px;
          height: 20px;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;

          @include responsive.media-breakpoint-down(sm) {
            width: 16px;
            height: 16px;
          }
        }

        img {
          margin-left: 6px;
          @include responsive.media-breakpoint-down(sm) {
            margin-left: 2px;
            height: 20px;
          }
        }
      }
    }
  }

  .item__top-svg {
    @include responsive.media-breakpoint-down(lg) {
      width: 64px;
      height: 64px;
    }
  }

  .swiper-scrollbar {
    display: none;

    @include responsive.media-breakpoint-down(xl) {
      display: block;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color: var(--white-100);
      border-radius: 10px;
      position: unset !important;
      margin-top: 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 24px;
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--green-100);
    border-radius: 10px;
  }

  .item__times {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    gap: 4px;
    padding: 0 12px;
    background-color: var(--red-20);
    width: fit-content;
    border-radius: 1000px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--red-100);
    position: absolute;
    left: 16px;
    top: 16px;

    &.plan {
      display: none;
    }

    @include responsive.media-breakpoint-down(lg) {
      display: none;

      &.plan {
        display: flex;
        position: static;
        align-self: flex-start;
        margin-bottom: auto;
      }
    }

    @include responsive.media-breakpoint-down(sm) {
      display: flex;
      font-size: 12px;
      height: 23px;
      gap: 3px;

      &.plan {
        display: none;
      }

      img {
        width: 11px;
        height: 11px;
      }
    }
  }

  .current-rates-swiper {
    .current-rates__item {
      @include responsive.media-breakpoint-down(lg) {
        min-height: 456px;
      }

      @include responsive.media-breakpoint-down(sm) {
        min-height: 633px;
      }

      @include responsive.media-breakpoint-down(smxs) {
        min-height: 677px;
      }
    }
  }
}
