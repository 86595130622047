@use '../mixins/responsive';

.consequences {

  .page-container-top-bottom {
    padding-top: 20px;

    @include responsive.media-breakpoint-down(exxl) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include responsive.media-breakpoint-down(xxl) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @include responsive.media-breakpoint-down(xl) {
      padding-top: 24px;
      padding-bottom: 0;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding-top: 32px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page-title__grey, .page-title__main {
    text-align: center;
  }

  .page-title__grey {
    color: var(--white-50);
  }

  .page-title__main {
    color: var(--white-100);
    margin-top: 20px;
    margin-bottom: 400px;
    max-width: 760px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 100px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 16px;
      margin-bottom: 444px;
      max-width: 685px;
      font-size: 80px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 12px;
      margin-bottom: 308px;
      max-width: 660px;
    }

    @include responsive.media-breakpoint-down(md) {
      font-size: 70px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-bottom: 200px;
      max-width: 320px;
      font-size: 32px;
    }
  }

  &__wrapper {
    background-color: var(--red-100);
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    padding: 60px 32px 23.5px 32px;

    @include responsive.media-breakpoint-down(xl) {
      padding: 60px 32px 18.5px 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      border-radius: 0;
      padding: 40px 32px 22.5px 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 32px 16px;
    }
  }

  .wrapper__bg {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 0;

    .mob {
      display: none;
    }

    @include responsive.media-breakpoint-down(sm) {
      .desc {
        display: none;
      }

      .mob {
        display: block;
      }
    }
  }

  .wrapper__content {
    z-index: 1;
    position: sticky;

    .bg-two-man {
      img {
        position: absolute;
        top: 87px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1200px;

        @include responsive.media-breakpoint-down(xxl) {
          top: unset;
          bottom: -23px;
        }

        @include responsive.media-breakpoint-down(xl) {
          max-width: 800px;
          top: 87px;
          bottom: unset;
          width: 100%;
        }

        @include responsive.media-breakpoint-down(lg) {
          max-width: 680px;
        }

        @include responsive.media-breakpoint-down(smd) {
          top: 200px;
        }

        @media(max-width: 576px) {
          width: 98.4%;
          top: 30px;
        }

        @media(max-width: 460px) {
          top: 122px;
          left: 51%;
          width: 369px;
        }
      }
    }
  }

  .content__down-container {
    position: relative;
    display: flex;
    overflow: hidden;

    @include responsive.media-breakpoint-down(sm) {
      overflow: unset;
    }
  }

  .desc-text-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 209px;
    width: 100%;
    margin-right: 144px;

    .text {
      font-size: 20px;
      font-weight: 400;
      color: var(--white-100);
      line-height: 1.2;
    }

    span {
      font-weight: 700;
    }

    @include responsive.media-breakpoint-down(xl) {
      display: none;
    }
  }

  .swiper-slide {
    background-color: var(--white-100);
    padding: 32px 32px 26px 32px;
    border-radius: 24px;
    max-width: 317px;

    @include responsive.media-breakpoint-down(xl) {
      padding: 24px;
      max-width: unset;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 24px 24px 44px 24px;
    }

    @include responsive.media-breakpoint-down(smd) {
      padding: 12px;
      width: 50%;
    }


    @include responsive.media-breakpoint-down(sm) {
      width: 148px;
    }
  }

  .slide__top {
    display: flex;
    align-items: center;

    div {
      &:nth-child(1) {
        background: var(--red-100);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white-100);
        font-size: 16px;
        font-weight: 700;
        border: 2px solid var(--white-100);
        z-index: 1;
        flex-shrink: 0;

        @include responsive.media-breakpoint-down(lg) {
          width: 40px;
          height: 40px;
        }

        @include responsive.media-breakpoint-down(sm) {
          width: 32px;
          height: 32px;
          font-size: 14px;
        }
      }

      &:nth-child(2) {
        background-color: rgba(255, 225, 224, 1);
        height: 100%;
        padding: 14px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;
        color: var(--red-100);
        font-size: 14px;
        font-weight: 600;
        position: relative;
        left: -12px;
        z-index: 0;

        @include responsive.media-breakpoint-down(lg) {
          padding: 10px 16px;
        }

        @include responsive.media-breakpoint-down(sm) {
          font-size: 12px;
          padding: 9px 12px;
        }
      }
    }
  }

  .slide__middle {
    color: rgba(19, 23, 21, 1);
    font-weight: 700;
    font-size: 24px;
    margin-top: 80px;
    margin-bottom: 40px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      font-weight: 600;
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .slide__bottom {
    color: var(--grey-100);
    font-size: 14px;
    font-weight: 400;
    padding-top: 22px;
    border-top: 1px solid var(--grey-10);

    @include responsive.media-breakpoint-down(xl) {
      padding-top: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding-top: 24px;
    }


    @include responsive.media-breakpoint-down(sm) {
      font-size: 12px;
      border-top: unset;
      padding-top: 0;
    }
  }

  .swiper {
    @include responsive.media-breakpoint-down(sm) {
      overflow: unset;
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-scrollbar {
    display: none;

    @include responsive.media-breakpoint-down(sm) {
      display: block;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color:rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      position: sticky !important;
      margin-top: 24px;
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--white-100);
    border-radius: 10px;
  }

  .main-banner_button-consultation {
    margin-top: 32px;
    margin-bottom: 10px;
    background-color: var(--black-100);
    transition: color .3s;
    z-index: 1;
    position: sticky;

    @media (any-hover: hover) {
      &:hover {
        color: var(--white-50);
      }
    }

    span {
      color: var(--white-50);
    }

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 20px;
      margin-bottom: 12px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .down-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(243, 247, 251, 1);
    position: sticky;
    z-index: 1;

    @include responsive.media-breakpoint-down(sm) {
      display: none;
    }
  }

  .arrow-top {
    position: sticky !important;
    height: unset !important;
    top: unset !important;
    left: unset !important;
    width: unset !important;
  }
}
