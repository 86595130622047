@use "../mixins/responsive";

@keyframes marqueeLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.header {
  width: 100%;

  &-top {
    padding: 9px 10px 11px;
    background: var(--grey-10);
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    gap: 24px;

    &_container {
      display: flex;
      justify-content: center;
      gap: 24px;
      width: max-content;
      margin: 0 auto;

      @include responsive.media-breakpoint-down(xl) {
        gap: 20px;
      }

      @include responsive.media-breakpoint-down(elg) {
        animation: marqueeLeft 25s linear infinite;
      }
      @include responsive.media-breakpoint-down(smd) {
        gap: 16px;
      }

      @include responsive.media-breakpoint-down(sm) {
        gap: 12px;
      }

      &__mob {
        display: none;
        @include responsive.media-breakpoint-down(elg) {
          display: flex;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &_item {
      color: var(--black-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: inline-flex;
      white-space: nowrap;

      &:before {
        content: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.1747 5.99547C16.3858 6.21164 16.3858 6.56213 16.1747 6.77831L9.11768 14.0045C8.90657 14.2207 8.56429 14.2207 8.35317 14.0045L4.82468 10.3914C4.61356 10.1752 4.61356 9.82476 4.82468 9.60858C5.03579 9.3924 5.37807 9.3924 5.58918 9.60858L8.73543 12.8303L15.4102 5.99547C15.6213 5.77929 15.9636 5.77929 16.1747 5.99547Z' fill='%23C3C8CF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.4132 5.76257C16.7507 6.10825 16.7507 6.66552 16.4131 7.0112L9.35616 14.2374C9.01426 14.5875 8.45659 14.5875 8.11469 14.2374L4.5862 10.6243C4.24861 10.2786 4.24861 9.72137 4.5862 9.37569C4.92809 9.02559 5.48576 9.02559 5.82766 9.37569L8.73543 12.3532L15.1717 5.76257C15.5136 5.41248 16.0713 5.41248 16.4132 5.76257ZM8.73543 12.8303L5.58918 9.60858C5.37807 9.3924 5.03579 9.3924 4.82468 9.60858C4.61356 9.82476 4.61356 10.1752 4.82468 10.3914L8.35317 14.0045C8.56429 14.2207 8.90657 14.2207 9.11768 14.0045L16.1747 6.77831C16.3858 6.56213 16.3858 6.21164 16.1747 5.99547C15.9636 5.77929 15.6213 5.77929 15.4102 5.99547L8.73543 12.8303Z' fill='%23C3C8CF'/%3E%3C/svg%3E%0A");
        margin-right: 2px;
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }

  }
}
