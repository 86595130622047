@use '../mixins/responsive';

.reputation-block {
  padding: 40px 0;

  &_buttons {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    @include responsive.media-breakpoint-down(xxl) {
      margin-top: 48px;
      gap: 20px;
      width: max-content;
    }
    @include responsive.media-breakpoint-down(elg) {
      display: flex;
      overflow: auto;
      width: 100vw;
      max-width: max-content;
      padding: 0 32px;
      gap: 12px;
      margin-top: 24px;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding: 0 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &_button {
    width: 160px;
    height: 160px;
    border-radius: 1142.857px;
    background: var(--white-100);
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    @include responsive.media-breakpoint-down(xxl) {
      width: 120px;
      height: 120px;
      flex: 1 0 auto;
    }
    @include responsive.media-breakpoint-down(smd) {
      width: 72px;
      height: 72px;
    }

    &:hover {
      background: var(--grey-10)
    }

    &.active {
      .autoplay-progress {
        display: flex;
      }
    }

    .autoplay-progress {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: var(--swiper-theme-color);
    }

    .autoplay-progress svg {
      --progress: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      stroke-width: 2px;
      stroke: var(--swiper-theme-color);
      fill: none;
      stroke-linecap: round;
      stroke-dashoffset: calc(502px * (1 - var(--progress)));
      stroke-dasharray: 502;
      transform: rotate(90deg);
    }
  }

  &_subtitle {
    margin-bottom: 20px;
    @include responsive.media-breakpoint-down(xxl) {
      margin-bottom: 16px;
    }
    @include responsive.media-breakpoint-down(elg) {
      margin-bottom: 12px;
    }
  }

  &_title {
    margin-bottom: 40px;
    @include responsive.media-breakpoint-down(xxl) {
      margin-bottom: 48px;
    }
    @include responsive.media-breakpoint-down(elg) {
      margin-bottom: 32px;
      text-align: center;
      max-width: 450px;
    }
  }

  &_achievements {
    display: flex;
    gap: 32px;
    @include responsive.media-breakpoint-down(xxl) {
      gap: 20px;
    }
    @include responsive.media-breakpoint-down(elg) {
      gap: 24px;
    }
    @include responsive.media-breakpoint-down(smxs) {
      gap: 16px;
    }

    &__delimiter {
      width: 1px;
      background: #E7EDF3;
      height: 100%;
      min-height: 84px;
      @include responsive.media-breakpoint-down(xxl) {
        min-height: 54px;
      }
      @include responsive.media-breakpoint-down(elg) {
        display: none;
      }
    }
  }

  &_achievement {
    position: relative;
    @include responsive.media-breakpoint-down(elg) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__number {
      color: var(--black-100);
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 4px;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      @include responsive.media-breakpoint-down(xxl) {
        font-size: 24px;
      }

      &:after {
        position: relative;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        @include responsive.media-breakpoint-down(xxl) {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__desc {
      color: var(--grey-100);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      @include responsive.media-breakpoint-down(xxl) {
        font-size: 14px;
      }
      @include responsive.media-breakpoint-down(smxs) {
        font-size: 12px;
      }
    }
  }

  &_container {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px !important;
    @include responsive.media-breakpoint-down(xxlm) {
      padding-right: 0 !important;
    }
    @include responsive.media-breakpoint-down(elg) {
      flex-direction: column;
      padding-right: 32px !important;
      width: 100%;
      gap: 24px;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding-right: 16px !important;
    }
  }

  &_left {
    max-width: 562px;
    @include responsive.media-breakpoint-down(xxl) {
      width: 50%;
    }
    @include responsive.media-breakpoint-down(elg) {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 100%;
    }
  }

  &_right {
    position: relative;
    border-radius: 40px;
    background: var(--white-100, #FFF);
    padding: 100px 80px;
    width: 720px;
    @include responsive.media-breakpoint-down(xxlm) {
      border-radius: 40px 0 0 40px;
    }
    @include responsive.media-breakpoint-down(xxl) {
      padding: 98px 48px 52px;
      width: 50%;
    }
    @include responsive.media-breakpoint-down(elg) {
      width: 100%;
      max-width: 680px;
      border-radius: 40px;
      padding: 98px 132px 52px;
    }
    @include responsive.media-breakpoint-down(smd) {
      padding: 61px 39px 35px;
    }
    @include responsive.media-breakpoint-down(smxs) {
      padding: 61px 20px 35px;
    }

    .swiper-slide:nth-child(1n) {
      background-color: var(--blue-100);
    }

    .swiper-slide:nth-child(2n) {
      background-color: rgb(0, 140, 255);
    }

    .swiper-slide:nth-child(3n) {
      background-color: rgb(10, 184, 111);
    }

    .swiper-slide:nth-child(4n) {
      background-color: rgb(247, 177, 73);
    }

    .swiper-slide:nth-child(5n) {
      background-color: rgb(14, 15, 17);
    }
  }

  &_cards {
    position: relative;
  }

  &_card {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 60px;
    height: 560px;
    border-radius: 24px;
    @include responsive.media-breakpoint-down(xxl) {
      height: 434px;
      padding: 0 24px;
    }
    @include responsive.media-breakpoint-down(elg) {
      padding: 120px 24px 0;
      justify-content: flex-start;
    }
    @include responsive.media-breakpoint-down(smd) {
      height: 384px;
    }
    @include responsive.media-breakpoint-down(sm) {
      padding: 60px 24px 0;
      height: 284px;
    }

    &_footer {
      position: absolute;
      bottom: 0;
      left: 16px;
      width: calc(100% - 32px);
      border-top: 1px solid rgba(255, 255, 255, 0.10);
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      @include responsive.media-breakpoint-down(elg) {
        position: relative;
        margin-top: auto;
        left: auto;
        width: 100%;
        padding: 16px 0;
      }

      & > *:first-child {
        margin-right: auto;
      }

      & > *:last-child {
        margin-left: auto;
      }

      &__link {
        display: flex;
        gap: 10px;
        align-items: center;

        &_text {
          color: var(--white-100);
          text-align: right;
          font-size: 10.928px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 15.299px */
        }
      }

      &__logo {
        max-height: 20px;
        @include responsive.media-breakpoint-down(elg) {
          max-height: 16px;
        }

        img {
          height: 100%;
        }
      }
    }

    &_sub-title {
      color: var(--white-50);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 20px;
      @include responsive.media-breakpoint-down(sm) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    &_title {
      color: var(--white-100);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      @include responsive.media-breakpoint-down(sm) {
        font-size: 18px;
        max-width: 300px;
      }
    }
  }

  &_bg {
    position: absolute;
    top: -56px;
    left: 0;
    width: 100%;
    @include responsive.media-breakpoint-down(smd) {
      top: -26px;
      height: 100%;
      overflow: hidden;
    }
  }
}
