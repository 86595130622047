@use '../mixins/responsive';

.video-modal {
  .modal-close {
    @include responsive.media-breakpoint-down(elg) {
      right: 0 !important;
    }
  }

  &_wrapper {
    --modal-wrapper-width: 1440px;
    flex-direction: column;
    --modal-wrapper-padding: 60px 30px;
    //@include responsive.media-breakpoint-down(elg) {
    //  --modal-wrapper-padding: 60px 30px;
    //}
    @include responsive.media-breakpoint-down(smd) {
      --modal-wrapper-padding: 60px 16px;
    }
  }

  &_container {
    --modal-midle-padding: 0;
    --modal-container-width: 1406px;
    height: 100%;
    max-height: 800px;
    @include responsive.media-breakpoint-down(elg) {
      border-radius: 32px !important;
      max-height: 400px;
      --modal-midle-min-height: 400px;
      margin: 0 !important;
    }
    @include responsive.media-breakpoint-down(smd) {
      --modal-midle-min-height: 300px;
      max-height: 300px;
    }
  }

  iframe {
    border-radius: 24px;
    width: 100%;
    height: 100%;
    @include responsive.media-breakpoint-down(smd) {
      border-radius: 16px;
    }
  }

}
