@use '../mixins/responsive';

.articles {
  .page-container {
    position: relative;
  }

  .page-container-right-left {
    margin-top: 60px;
    margin-bottom: 60px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }

  .page-title__main {
    @include responsive.media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__top-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .circle-black {
    background-color: var(--black-100);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &__is-new {
    color: var(--green-100) !important;
  }

  &__title, strong {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    font-size: 20px;
    line-height: 28px;

    @include responsive.media-breakpoint-down(xl) {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    border-radius: 24px;
    background-color: var(--white-100);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 490px;
    max-width: 321px;
    transition: transform 5s;

    @include responsive.media-breakpoint-down(xl) {
      min-height: 470px;
      max-width: 312px;
    }

    @include responsive.media-breakpoint-down(lg) {
      min-height: 468px;
      max-width: 334px;
    }

    @include responsive.media-breakpoint-down(sm) {
      width: 300px;
      min-height: 409px;
    }

    @include responsive.media-breakpoint-down(smxs) {
      width: 100%;
    }

    &__top-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 20px;
      padding: 32px 32px 0;

      @include responsive.media-breakpoint-down(xl) {
        padding: 24px 24px 0;
      }

      @include responsive.media-breakpoint-down(lg) {
        padding: 24px 24px 0;
      }

      strong {
        font-weight: 600;
      }

      span {
        font-size: 16px;
        line-height: 22.4px;
        color: var(--grey-100);

        @include responsive.media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 19.6px;
        }
      }
    }

    &__bottom-content {
      object-fit: cover;
      position: relative;

      img {
        transition: transform .3s;
      }
    }

    .bottom-content__icon-for-img {
      position: absolute;
      right: 20px;
      bottom: 20px;
      background-color: var(--white-100);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 52px;
      min-height: 76px;
      border-radius: 1000px;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
      z-index: 1;

      @include responsive.media-breakpoint-down(xl) {
        right: 16px;
        bottom: 16px;
      }

      svg {
        margin-bottom: 6px;
      }
    }

    @media (any-hover: hover) {
      &:hover .swiper-slide__bottom-content img {
        transform: scale(1.1);
      }
    }
  }

  .swiper-slide__bottom-content {
    img {
      width: 100%;
    }
  }

  .swiper-wrapper {
    margin-bottom: 30px;

    @include responsive.media-breakpoint-down(xl) {
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
  }

  .swiper-scrollbar {
    display: none;

    @include responsive.media-breakpoint-down(xl) {
      display: block;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color:var(--white-100);
      border-radius: 10px;
      position: unset !important;
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--green-100);
    border-radius: 10px;
  }

  .arrows-right-left {
    position: absolute;
    right: 41px;
    bottom: 40%;
    z-index: 2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include responsive.media-breakpoint-down(xl) {
      display: none;
    }
  }

  .arrow {
    background-color: var(--green-100);
    box-shadow: 1px 8px 20px 0 rgba(12, 13, 16, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;

    &:nth-child(2) {
      transform: rotate(180deg);
    }

    &-left, &-right {
      transition: transform .3s;

      &:not(.swiper-button-disabled) {
        &:hover {
          background-color: rgba(26, 131, 98, 1);
        }
      }
    }
  }

  .grey.articles {
    display: none;

    @include responsive.media-breakpoint-down(sm) {
      display: flex;
      margin-top: 24px;
    }
  }
}
