@use '../mixins/responsive';

.footer-down {
  margin-top: 18px;
  margin-bottom: 44px;
  z-index: 3;
  position: sticky;

  @include responsive.media-breakpoint-down(xl) {
    margin-top: 40px;
    margin-bottom: 32px;
  }

  @include responsive.media-breakpoint-down(sm) {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .footet-down__wrapper {
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: var(--grey-100);
      margin-bottom: 12px;

      @include responsive.media-breakpoint-down(xl) {
        margin-bottom: 8px;
      }

      @include responsive.media-breakpoint-down(sm) {
        margin-bottom: 4px;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 56px;
      line-height: 56px;
      color: var(--white-100);

      @include responsive.media-breakpoint-down(xl) {
        font-size: 48px;
        line-height: 48px;
      }

      @include responsive.media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
  }

  .footer-down__title-and-img {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-img {
      transition: fill .3s;

      @include responsive.media-breakpoint-down(xl) {
        width: 48px;
        height: 48px;
      }

      @include responsive.media-breakpoint-down(sm) {
        width: 29px;
        height: 29px;
      }

      @media (any-hover: hover) {
        &:hover rect {
          fill: var(--grey-10);
        }
      }
    }
  }
}
