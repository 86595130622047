@use "mixins/_reset.scss";
@use "fonts";
@use "vars";
@use "mixins/responsive";
@import "components/btn.scss";
@import 'swiper/css/bundle';

@import "components/before";

@import "components/header";
@import "components/header-top";
@import "components/header-middle";
@import "components/header-burger";
@import "components/header-bottom";
@import "components/header-submenu";

@import "components/modal";
@import "components/switcher";
@import "components/input";

@import "components/footer-top";
@import "components/footer-middle";
@import "components/footer-swiper";
@import "components/footer-down";

@import "components/bankruptcy";

@import "components/reviews";

@import "components/articles";

@import "components/current-rates";

@import "components/main-banner";

@import "components/consultation-modal";

@import "components/services-list";

@import "components/garant-block";

@import "components/answer-questions";

@import "components/plan-block";

@import "components/reputation-block";

@import "components/consequences";

@import "components/main-banner-animation";

@import "components/video-modal";

body {
  width: 100%;
  background-color: var(--Card-Background);

  &.hidden {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
}

main {
  position: relative;
  flex: 1 0 auto;
}

a {
  cursor: pointer;
  text-decoration: none;
}

* {
  font-family: 'SF UI Display', sans-serif !important;
}

.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  @include responsive.media-breakpoint-down(xl) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include responsive.media-breakpoint-down(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-container {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  @include responsive.media-breakpoint-down(xl) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include responsive.media-breakpoint-down(smd) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.hide_elg {
  @include responsive.media-breakpoint-down(elg) {
    display: none;
  }
}
.hide_md {
  @include responsive.media-breakpoint-down(smd) {
    display: none;
  }
}

.page-title__main {
  color: var(--black-100);
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 56px */

  @include responsive.media-breakpoint-down(xl) {
    font-size: 48px;
  }
  @include responsive.media-breakpoint-down(smd) {
    font-size: 32px;
  }
  span {
    color: var(--green-100);
  }
}

.page-title__grey {
  font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--grey-50);
    z-index: 1;

    @include responsive.media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 19.6px;
    }
}

.swiper-button-lock {
  opacity: 0;
  pointer-events: none;
}

.swiper-button-next, .swiper-button-prev {
  &:after {
    content: none !important;
  }
}
