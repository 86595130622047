@use '../mixins/responsive';

.switcher {
  position: relative;

  &_container {
    display: flex;
    border-radius: 12px;
    background: var(--grey-10);
    padding: 4px;
  }

  &_item {
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    border-radius: 8px;
    color: var(--grey-100);
    cursor: pointer;
    transition: 0.3s;
    @include responsive.media-breakpoint-down(lg) {
      padding: 10px 7px;
    }

    &__text {
      color: inherit;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      white-space: nowrap;
    }

    svg {
      path {
        transition: fill .3s;
      }
    }

    &.active {
      pointer-events: none;
      color: var(--black-100);
      background: var(--white-100, #FFF);
      box-shadow: 0px 10px 34px 0px rgba(0, 26, 52, 0.08);

      svg path {
        fill: var(--black-100);
      }
    }

    @media (any-hover: hover) {
      &:hover {
        color: var(--black-100);

        svg path {
          fill: var(--black-100);
        }
      }
    }

  }

  &.--articles {
    width: max-content;
    margin: 32px 0;
    position: relative;

    @include responsive.media-breakpoint-down(sm) {
      width: auto;
      overflow: hidden;

      &::after {
        content: "";
        background: linear-gradient(to left,rgba(243, 247, 251, 1), rgba(255, 255, 255, 0));
        position: absolute;
        right: -18px;
        bottom: 0;
        width: 82px;
        height: 100%;
        pointer-events: none;
      }
    }
    .switcher_container {
      overflow-x: auto;
      white-space: nowrap;

      @include responsive.media-breakpoint-down(sm) {
        margin-right: 20px;

        /* Скрываем scrollbar для Chrome, Safari и Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;  /* IE и Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }

    .switcher__item {
      width: fit-content;
      z-index: 1;
    }
  }

  &-and-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include responsive.media-breakpoint-down(sm) {
      width: 100vw;
      margin-left: -16px;
      padding-left: 16px;

      .button {
        display: none;
      }
    }
  }
}
