@use '../mixins/responsive';

.header-submenu {
  position: absolute;
  width: 100%;
  height: calc(100vh - 162px);
  top: 100%;
  left: 0;
  pointer-events: none;
  transition: 0.3s;
  opacity: 0;

  @include responsive.media-breakpoint-down(elg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(14, 15, 17, 0.60);
    z-index: 0;
    opacity: 0;
    transition: 0.3s;
  }

  &_container {
    position: relative;
    top: -1px;

    border-radius: 0 0 32px 32px;
    background: var(--white-100);

    max-height: 0;
    z-index: 1;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    height: max-content;
    overflow: auto;
    transition: 0.3s;

    @include responsive.media-breakpoint-down(elg) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      display: block;
      gap: 0;
      padding-top: 0;
    }

    @include responsive.media-breakpoint-down(smd) {
      padding-bottom: 0;
    }

    .page-container {
      width: 100%;
    }
  }

  &.open {
    pointer-events: auto;
    opacity: 1;

    .header-submenu_bg {
      opacity: 1;
    }

    .header-submenu_container {
      max-height: calc(100% - 12px);
      @include responsive.media-breakpoint-down(elg) {
        max-height: 100%;
      }
    }
  }

  &_list-container {
    display: flex;
    margin-bottom: 10px;
    @include responsive.media-breakpoint-down(smd) {
      flex-direction: column;
    }
    @include responsive.media-breakpoint-down(elg) {
      gap: 24px;
      padding-top: 15px;
    }
  }

  &_list {
    width: 100%;

    &__left {
      .header-submenu_title {
        padding-right: 16px;
        @include responsive.media-breakpoint-down(xl) {
          padding-right: 12px;
        }
        @include responsive.media-breakpoint-down(smd) {
          padding-right: 0;
        }
      }

      .header-submenu_list-content {
        padding-right: 16px;
        @include responsive.media-breakpoint-down(xl) {
          padding-right: 12px;
        }
        @include responsive.media-breakpoint-down(smd) {
          padding-right: 0;
        }
      }
    }

    &__right {
      .header-submenu_title {
        padding-left: 16px;
        @include responsive.media-breakpoint-down(xl) {
          padding-left: 12px;
        }
        @include responsive.media-breakpoint-down(smd) {
          padding-left: 0;
        }
      }

      .header-submenu_list-content {
        padding-left: 16px;
        @include responsive.media-breakpoint-down(xl) {
          padding-left: 12px;
        }
        @include responsive.media-breakpoint-down(smd) {
          padding-left: 0;
        }
      }
    }
  }

  &_title {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey-10);
    @include responsive.media-breakpoint-down(elg) {
      gap: 8px;
      padding-bottom: 16px;
      border-bottom: none;
    }

    &__ico {
      width: 32px;
      height: 32px;
    }

    &__text {
      color: var(--black-100);
      font-feature-settings: 'ss12' on;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      @include responsive.media-breakpoint-down(elg) {
        font-size: 18px;
      }
    }
  }

  &_list-content {
    display: flex;
    gap: 12px;
    padding-top: 24px;
    @include responsive.media-breakpoint-down(xl) {
      flex-direction: column;
    }
    @include responsive.media-breakpoint-down(elg) {
      padding-top: 0;
    }
  }

  &_list-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include responsive.media-breakpoint-down(xl) {
      gap: 12px;
    }
    @include responsive.media-breakpoint-down(elg) {
      gap: 10px;
    }
  }

  &_list-item {
    color: var(--grey-100);
    font-size: 14px;
     font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    transition: 0.3s;

    &:hover {
      color: var(--black-100);
    }

    &__red {
      color: var(--red-100);
      display: flex;
      align-items: center;

      &:before {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  &_mobile {
    display: none;
    @include responsive.media-breakpoint-down(elg) {
      display: block;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 5px;
      padding-top: 24px;
    }

    &-title {
      color: var(--black-100);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
    }

    &-close {
      border-radius: 10px;
      background: var(--grey-10);
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_sticky-block {
      display: none;
      top: 0;
      position: sticky;
      width: 100%;
      flex: 1 0 auto;
      background: var(--white-100);
      padding: 12px;

      @include responsive.media-breakpoint-down(elg) {
        display: block;
      }
    }

    &_close {
      &-container {
        display: none;
        @include responsive.media-breakpoint-down(elg) {
          display: block;
          z-index: 9999999;
        }
        @include responsive.media-breakpoint-down(smd) {
          position: sticky;
          bottom: 0;
          padding-top: 12px;
          padding-bottom: 12px;
          background-color: var(--white-100);
        }
      }

      &-btn {
        width: 100%;
        color: var(--black-100);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }

  &_banks {
    padding-top: 30px;
    position: relative;
    @include responsive.media-breakpoint-down(xl) {
      margin-bottom: 16px;
      padding-top: 22px;
    }
    @include responsive.media-breakpoint-down(md) {
      margin-bottom: 10px;
      padding-top: 24px;
    }

    .header-submenu_title {
      padding-bottom: 16px;
      //border-bottom: 1px solid var(--grey-10);
      @include responsive.media-breakpoint-down(md) {
        padding-bottom: 19px;
      }
    }

    .page-container {
      position: relative;
    }

    .swiper-container {
      position: relative;
      margin: 0 auto;
      max-width: 1440px;
      padding-left: 60px;
      padding-right: 60px;
      @include responsive.media-breakpoint-down(xl) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .swiper {
      &::after, &::before {
        top: 0;
        z-index: 1;
        position: absolute;
        right: 0;
        height: 100%;
        content: "";
        width: 100%;
        pointer-events: none;
        background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 20.61%);
        @include responsive.media-breakpoint-down(xl){
          content: none;
        }
      }
      &::before {
        z-index: 2;
        background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 20.61%);
      }

      &.onStart {
        &::before {
          opacity: 0;
        }
      }

      &.onEnd {
        &::after {
          opacity: 0;
        }
      }
    }

    &-list {
      padding: 20px 0;
      @include responsive.media-breakpoint-down(xl) {
        padding: 20px 0 16px 0;
      }
    }
    &_scrolbar.swiper-scrollbar {
      display: none;

      top: 0;
      --swiper-scrollbar-sides-offset: 0;
      position: relative;
      width: 100%;
      height: 8px;
      background-color: var(--Card-Background);
      border-radius: 10px;
      @include responsive.media-breakpoint-down(xl) {
        display: block;
      }

      .swiper-scrollbar-drag {
        border-radius: 1000px;
        background: var(--green-100);
      }
    }

    .swiper {
      width: 100%;
    }

    &_show-all {
      width: max-content;
      border-radius: 10px;
      background: var(--grey-10);
      padding: 10px 16px;
      color: var(--black-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      text-align: center;
      transition: 0.3s;
      &:hover {
        background: #DDE5EC;
      }

      &__title {
        margin-left: auto;
        @include responsive.media-breakpoint-down(md) {
          display: none;
        }
      }
      &__bottom {
        display: none;
        margin-top: 20px;
        width: 100%;
        @include responsive.media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }

  &_bank {
    display: flex;
    gap: 8px;
    padding: 4px 16px 4px 4px;
    border-radius: 1000px;
    background: var(--Card-Background);
    align-items: center;
    width: max-content;
    cursor: pointer;

    &:hover {
      --bank-color: var(--black-100);
    }

    &:first-child {
      @include responsive.media-breakpoint-down(xl) {
        margin-left: 32px;
      }
      @include responsive.media-breakpoint-down(smd) {
        margin-left: 16px;
      }
    }
    &:last-child {
      @include responsive.media-breakpoint-down(xl) {
        margin-right: 32px;
      }
      @include responsive.media-breakpoint-down(smd) {
        margin-right: 16px;
      }
    }
    &__ico {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 50%;
    }
    &__title {
      transition: 0.3s;
      color: var(--bank-color, var(--grey-100));
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
    }
  }
  &_nav {
    border-radius: 10px;
    background: var(--white-100, #FFF);
    box-shadow: 1px 8px 20px 0px rgba(12, 13, 16, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    --swiper-navigation-sides-offset: 60px;
    --swiper-navigation-top-offset: 52%;

    &:hover {
      svg, path {
        fill: var(--green-100);
      }
    }
    svg, path {
      transition: 0.3s;
    }
    @include responsive.media-breakpoint-down(xl) {
      display: none;
    }

    &.swiper-button-disabled {
      display: none;
    }

    &.swiper-button-prev {
      transform: rotate(180deg);
    }
  }
}
