@use '../mixins/responsive';

.header-bottom {
  display: flex;
  gap: 24px;
  padding-bottom: 20px;
  width: 100%;
  overflow: auto;

  @include responsive.media-breakpoint-down(xl) {
    gap: 18px;
  }
  @include responsive.media-breakpoint-down(elg) {
    padding-bottom: 0;
    gap: 7.33px;
  }

  &_logo {
    display: flex;
    gap: 12px;
    margin-left: -4px;
    @include responsive.media-breakpoint-down(elg) {
      display: none;
    }
  }

  &_submenu {
    display: flex;
    gap: 12px;
    @include responsive.media-breakpoint-down(elg) {
      gap: 7.33px;
    }
    &_item {
      padding: 10px 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 10px;
      background: var(--green-100);
      transition: 0.3s;

      &.open {
        .header-bottom_submenu_item__arrow {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background: #1A8362;
      }

      &__text {
        display: flex;
        align-items: center;
        color: var(--white-100);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        white-space: nowrap;

        &:before {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }

      &__arrow {
        width: 16px;
        height: 16px;
        transform: rotate(0deg);
        transition: 0.3s;
      }
    }
  }

  &_alphabet-btn {
    width: max-content;
    margin-left: auto;
    border-radius: 10px;
    background: var(--green-10);

    padding: 10px 16px;

    color: var(--green-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    white-space: nowrap;
  }
}
