@use '../mixins/responsive';

.garant-block {
  padding-top: 60px;
  position: relative;
  @include responsive.media-breakpoint-down(xl) {
    padding-top: 40px;
  }
  @include responsive.media-breakpoint-down(elg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @include responsive.media-breakpoint-down(smd) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 587px;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(243, 247, 251, 0.00) 46.69%, #F3F7FB 88.12%);
    z-index: 1;
    @include responsive.media-breakpoint-down(xl) {
      height: 418px;
      background: linear-gradient(180deg, rgba(243, 247, 251, 0.00) 46.69%, #F3F7FB 90.64%);
    }
    @include responsive.media-breakpoint-down(elg) {
      content: unset;
    }
  }

  &_titles {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 660px;
    margin: 0 auto;
    @include responsive.media-breakpoint-down(xl) {
      max-width: 560px;
    }
    @include responsive.media-breakpoint-down(elg) {
      margin-bottom: 32px;
    }
    @include responsive.media-breakpoint-down(smd) {
      max-width: 100%;
    }

    .page-title__grey {
      margin-bottom: 24px;
      text-align: center;
      @include responsive.media-breakpoint-down(xl) {
        margin-bottom: 16px;
      }
      @include responsive.media-breakpoint-down(elg) {
        margin-bottom: 12px;
      }
    }

    .page-title__main {
      margin-bottom: 24px;
      text-align: center;
      @include responsive.media-breakpoint-down(elg) {
        margin-bottom: 26px;
      }
    }
  }

  &_desc {
    color: #131715;
    text-align: center;
    font-feature-settings: 'ss12' on;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    margin-bottom: 32px;
    @include responsive.media-breakpoint-down(xl) {
      font-size: 16px;
      margin-bottom: 24px;
    }

    b {
      font-weight: 600;
    }
  }

  &_cards {
    position: relative;
    z-index: 1;
    margin-top: -197px;
    display: flex;
    gap: 16px;

    height: 712px;
    overflow: hidden;

    padding-top: 134px;

    @include responsive.media-breakpoint-down(xl) {
      gap: 12px;
      height: 633px;
      margin-top: -207px;
    }
    @include responsive.media-breakpoint-down(elg) {
      display: none;
    }

    &-table {
      display: none;
      flex-wrap: wrap;
      gap: 12px;
      @include responsive.media-breakpoint-down(elg) {
        display: flex;
      }
      @include responsive.media-breakpoint-down(smd) {
        gap: 6px;
      }
    }

    &-column {
      width: 100%;

      div:first-child {
        margin-bottom: 16px;
        @include responsive.media-breakpoint-down(xl) {
          margin-bottom: 12px;
        }
      }

      &:nth-child(2) {
        padding-top: 188px;
        @include responsive.media-breakpoint-down(xl) {
          padding-top: 168px;
        }
      }

      &:nth-child(3) {
        padding-top: 104px;
        @include responsive.media-breakpoint-down(xl) {
          padding-top: 92px;
        }
      }
    }
  }

  &_card {
    position: relative;
    border-radius: 24px;
    border: 1px solid var(--grey-10);
    background: var(--garant-card-bg, var(--white-100));
    width: 100%;
    height: 246px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    @include responsive.media-breakpoint-down(xl) {
      height: auto;
      justify-content: flex-start;
      gap: 60px;
      padding: 0 12px;
    }
    @include responsive.media-breakpoint-down(elg) {
      width: 32%;
      justify-content: flex-end;
    }
    @include responsive.media-breakpoint-down(smd) {
      width: 49%;
      justify-content: space-between;
      gap: unset;
      height: 177px;
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 5;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 3;
      }
      &:nth-child(5) {
        order: 6;
      }
      &:nth-child(6) {
        order: 4;
      }
    }
    @include responsive.media-breakpoint-down(smxs) {
      width: 100%;
    }

    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      pointer-events: none;

      &_right {
        position: absolute;
        right: -1px;
        top: -1px;

        @include responsive.media-breakpoint-down(xl) {
          width: 64px;
          height: 64px;
        }
        @include responsive.media-breakpoint-down(smd) {
          width: 48px;
          height: 48px;
        }
      }

      &_left {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.1;
        width: 171px;
        height: 151px;
        @include responsive.media-breakpoint-down(xl) {
          width: 171px;
          height: 151px;
        }
        @include responsive.media-breakpoint-down(elg) {
          width: 151px;
        }
        @include responsive.media-breakpoint-down(smd) {
          width: 80px;
          height: 81px;
        }
      }
    }

    &.green {
      --garant-title-color: var(--white-100);
      --garant-card-bg: linear-gradient(237deg, #1DB173 0%, #28906F 100%);
      border-radius: 24px 0px 24px 24px;
      box-shadow: -16px 24px 32px 0px rgba(0, 52, 15, 0.12);
      z-index: 0;
      @include responsive.media-breakpoint-down(sm) {
        box-shadow: -7.764px 11.646px 15.528px 0px rgba(0, 52, 15, 0.12);
      }
      .garant-block_card_title {
        @include responsive.media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }

      .garant-block_card_desc {
        padding: 11px 24px;
        border-color: rgba(255, 255, 255, 0.10);
        color: var(--white-50);
        @include responsive.media-breakpoint-down(xl) {
          padding: 16px 12px 20px;
        }
        @include responsive.media-breakpoint-down(sm) {
          border-top: none;
          padding: 12px 0 16px;
        }

        b {
          color: var(--white-100);
        }
      }
    }

    &.gray {
      --garant-title-opacity: 0.6;
    }

    &_title {
      opacity: var(--garant-title-opacity, 1);
      color: var(--garant-title-color, var(--black-100));
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 76.8px */
      margin-top: 60px;
      position: relative;

      @include responsive.media-breakpoint-down(xl) {
        font-size: 48px;
        line-height: 100%;
      }
      @include responsive.media-breakpoint-down(smd) {
        margin-top: 40px;
      }
      @include responsive.media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 120%;
      }

      &__mark {
        position: absolute;
        left: 100%;
        width: 24px;
        height: 24px;
        top: 2px;
        display: flex;
        @include responsive.media-breakpoint-down(xxl) {
          left: 103%;
          top: -10px;
          width: 20px;
          height: 20px;
        }

        &_arrow {
          margin-left: 9px;
          @include responsive.media-breakpoint-down(xxl) {
            transform: rotateY(180deg);
          }
        }

        &_desc {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          position: absolute;
          bottom: 70%;
          left: -18px;
          @include responsive.media-breakpoint-down(xxl) {
            left: auto;
            right: -4px;
          }

          &_text {
            width: 126px;
            color: var(--green-100);
            transform: rotate(-10deg);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            @include responsive.media-breakpoint-down(xl) {
              transform: rotate(10deg);
            }
          }
        }
      }
    }

    &_desc {
      padding: 18px 24px 22px;
      color: var(--garant-title-color, var(--black-100));
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      border-top: 1px solid var(--grey-10);
      width: 100%;
      @include responsive.media-breakpoint-down(xl) {
        padding: 16px 12px 20px;
      }
      @include responsive.media-breakpoint-down(elg) {
        min-height: 68px;
        font-size: 14px;
      }
      @include responsive.media-breakpoint-down(smd) {
        padding: 12px 0px 16px;
        font-weight: 500;
      }

      b {
        font-weight: 500;
      }
    }

    &_ico {
      position: absolute;
      top: 18px;
      left: 16px;
      width: 28px;
      height: 28px;
      @include responsive.media-breakpoint-down(xl) {
        width: 24px;
        height: 24px;
      }
      @include responsive.media-breakpoint-down(smd) {
        top: 12px;
        left: 12px;
      }
      @include responsive.media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
