@use '../mixins/responsive';

.button {
  width: 100%;
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  transition: .3s;
  cursor: pointer;

  @include responsive.media-breakpoint-down(smxs) {
    font-size: 13px;
  }

  &.light-green {
    background-color: var(--green-10);
    color: var(--green-100);
    transition: background-color .3s;

    span {
      color: var(--black-100) !important;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: rgba(200, 236, 225, 1);
      }
    }
  }

  &.green {
    background-color: var(--green-100);
    color: var(--white-100);

    @media (any-hover: hover) {
      &:hover {
        background-color: rgba(26, 131, 98, 1);
      }
    }
  }

  &.grey {
    background-color: var(--grey-10);
    width: fit-content;
    padding: 0 20px;
    color: var(--black-100);

    @include responsive.media-breakpoint-down(sm) {
      width: 100%;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: rgba(221, 228, 234, 1);
      }
    }
  }

  &.blue {
    background-color: #DEE7FF;
    height: 48px;
    color: var(--blue-100);
    transition: color .3s;
    width: fit-content;
    padding: 0 20px;

    @include responsive.media-breakpoint-down(sm) {
      padding: unset;
      width: 100%;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: #C8D7FF;
      }
    }
  }

  &.black {
    background-color: var(--black-100);
    height: 64px;
    color: var(--white-100);
    transition: color .3s;

    @include responsive.media-breakpoint-down(xl) {
      height: 60px;
    }

    @include responsive.media-breakpoint-down(sm) {
      height: 48px;
    }

    @media (any-hover: hover) {
      &:hover {
        color: var(--white-50);
      }
    }
  }
}

.button-big {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding: 22.5px 32px;
  transition: 0.3s;
  border-radius: 16px;
  @include responsive.media-breakpoint-down(lg) {
    padding: 14px 24px;
    font-size: 14px;
    line-height: 140%;
  }

  &.blue {
    color: var(--white-100);
    background: var(--blue-100);

    &:hover {
      background: #1146EF;
    }
  }
}
