@use '../mixins/responsive';

.header-middle {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @include responsive.media-breakpoint-down(elg) {
    padding: 16px 0 20px;
  }

  &_left {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &_right {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .header-logo {
    display: none;
    @include responsive.media-breakpoint-down(elg) {
      display: block;
    }
  }

  .header-logo_desc {
    display: none;
    @include responsive.media-breakpoint-down(elg) {
      display: block;
    }
    @include responsive.media-breakpoint-down(smd) {
      display: none;
    }
  }
}
