@use '../mixins/responsive';

.input-container {
  position: relative;
  background: #FFF;

  &.error {
    --border-color: var(--red-100);
    --label-color: var(--red-100);
    --errorMark-opacity: 1;
  }

  &.filled {
    label {
      top: -9px;
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    height: 100%;
    color: var(--black-100);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    border: 1.2px solid var(--border-color, var(--grey-10));
    padding: 19px 20px;
    border-radius: 16px;
    transition: 0.3s;
    @include responsive.media-breakpoint-down(md) {
      padding: 15px 16px;
      font-size: 14px;
    }

    &:focus ~ label {
      top: -9px;
      font-size: 12px;
      color: var(--blue-100);
    }

    &:focus {
      border: 1.2px solid #2557F6;
      box-shadow: 0px 0px 0px 4px rgba(222, 231, 255, 0.50);
    }
  }

  label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 19px;
    transition: 0.2s ease all;
    padding: 2px 4px;
    background: var(--white-100);

    color: var(--label-color, var(--grey-100));
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    @include responsive.media-breakpoint-down(md) {
      top: 12px;
      left: 16px;
    }
  }

  &_errorMark {
    opacity: var(--errorMark-opacity, 0);
    position: absolute;
    right: 20px;
    top: 19px;
    width: 20px;
    height: 20px;
    @include responsive.media-breakpoint-down(md) {
      top: 12px;
      right: 16px;
    }

    &:hover {
      --tooltip-opasity: 1;
    }

    &__tooltip {
      opacity: var(--tooltip-opasity, 0);
      color: var(--white-100);
      pointer-events: none;
      position: absolute;
      right: -14px;
      padding: 8px 12px;
      border-radius: 10px;
      background: var(--red-100);
      box-shadow: 0px 10px 34px 0px rgba(0, 26, 52, 0.08);
      top: calc(100% + 10px);
      width: max-content;
      z-index: 1;
      transition: 0.3s;

      &::before {
        width: 24px;
        height: 14px;
        position: absolute;
        top: -11px;
        right: 9px;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='none'%3E%3Cpath d='M9.71074 0.718109C9.3194 0.322712 8.6806 0.322712 8.28926 0.718109L0.293675 8.79655C-0.331287 9.42799 0.115996 10.5 1.00442 10.5L16.9956 10.5C17.884 10.5 18.3313 9.42798 17.7063 8.79655L9.71074 0.718109Z' fill='%23F24C41'/%3E%3C/svg%3E");
      }
    }
  }
}
