@use '../mixins/responsive';

.answer-questions {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 36px;
    justify-content: space-between;

    @include responsive.media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .page-container {
    padding-right: 24px;
    @include responsive.media-breakpoint-down(xxlm) {
      padding-right: 0;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding-right: 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding-right: 16px;
    }
  }

  .page-container-top-bottom {
    padding-top: 40px;
    padding-bottom: 40px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 24px;
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }

  .page-title__main {
    margin-bottom: 32px;
    margin-top: 24px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 12px;
      text-align: center;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 12px;
      margin-bottom: 16px;
    }
  }

  .wrapper__left  {
    max-width: 600px;
    width: 100%;

    @include responsive.media-breakpoint-down(xl) {
      max-width: 430px;
    }

    @include responsive.media-breakpoint-down(lg) {
      max-width: 100%;
    }

    @include responsive.media-breakpoint-down(sm) {
      max-width: 327px;
    }

    .left__text-content {
      color: var(--black-100);
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;

      @include responsive.media-breakpoint-down(xl) {
        max-width: 260px;
        font-size: 16px;
      }

      @include responsive.media-breakpoint-down(lg) {
        max-width: 460px;
        text-align: center;
      }

      @include responsive.media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    .left__info-container {
      max-width: 387px;
      width: 100%;
      position: relative;

      @include responsive.media-breakpoint-down(xl) {
        max-width: 400px;
      }

      @include responsive.media-breakpoint-down(lg) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      svg {
        position: absolute;
        right: -244px;
        top: 131px;

        @include responsive.media-breakpoint-down(xxl) {
          display: none;
        }

        @include responsive.media-breakpoint-down(xl) {
          display: block;
          right: -48px;
          top: 122px;
          width: 214px;
        }

        @include responsive.media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .wrapper__right  {
    max-width: 720px;
    width: 100%;
    background-color: var(--green-100);
    position: relative;
    border-radius: 40px;
    padding: 33px 80px 60px 80px;
    display: flex;
    justify-content: center;

    @include responsive.media-breakpoint-down(exxl) {
      border-radius: 32px;
    }

    @include responsive.media-breakpoint-down(xxlm) {
      border-top-left-radius: 32px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 32px;
    }

    @include responsive.media-breakpoint-down(xl) {
      max-width: 512px;
      max-width: 52%;
      padding: 30px 46px 30px 46px;
    }

    @include responsive.media-breakpoint-down(lg) {
      max-width: 100%;
      padding: 44px 0 40px 0;
      border-radius: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 33px 24px 30px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__bg {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 0;
      left: 0;
      top: 0;
      border-radius: 40px;

      @include responsive.media-breakpoint-down(exxl) {
        border-top-left-radius: 32px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 32px;
      }

      @include responsive.media-breakpoint-down(lg) {
        border-radius: 24px;
      }
    }

    &__inner-container {
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding-top: 54.91px;

      @include responsive.media-breakpoint-down(xl) {
        padding-top: 30px;
      }

      @include responsive.media-breakpoint-down(lg) {
        padding-top: 34.9px;
        max-width: 448px;
      }

      @include responsive.media-breakpoint-down(sm) {
        padding-top: 25px;
      }
    }
  }

  .red-orange-bg {
    position: absolute;
    top: 0;
    right: 10.13px;
  }

  .inner-container__top {
    background-color: var(--blue-100);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 60px 32px 82px 32px;
    position: relative;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    gap: 20px;

    @include responsive.media-breakpoint-down(xl) {
      padding: 48px 32px 53px 32px;
      gap: 12px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 40px 46px 52px 46px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 28px 24px 40px 24px;
    }
  }

  .inner-container__top__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
    text-align: center;
    color: var(--white-100);

    @include responsive.media-breakpoint-down(xl) {
      font-size: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .inner-container__bottom {
    background-color: var(--white-100);
    padding: 48px 32px 32px;
    position: relative;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;

    @include responsive.media-breakpoint-down(xl) {
      padding: 20px 24px 24px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 20px 24px 24px;
    }
  }

  button, .button {
    position: sticky;
    z-index: 1;
  }

  .left__down-wrapper {
    background-color: var(--blue-100);
    padding: 32px;
    border-radius: 24px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;

    &.mob {
      display: none;
    }

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 126px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 24px;
      margin-top: 0;

      &.desc {
        display: none;
      }

      &.mob {
        display: block;
        width: 100%;
      }
    }
  }

  .down-wrapper__title, .down-wrapper__subtitle {
    max-width: 320px;
    width: 100%;
    color: var(--white-100);

    @include responsive.media-breakpoint-down(sm) {
      max-width: 218px;
    }
  }

  .down-wrapper--bg {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    pointer-events: none;

    @include responsive.media-breakpoint-down(xxl) {
      max-width: 187px;
    }

    @include responsive.media-breakpoint-down(lg) {
      max-width: 100%;
      right: 40px;
    }
    @include responsive.media-breakpoint-down(smd) {
      right: -65px;
    }
    @include responsive.media-breakpoint-down(sm) {
      right: 0;
    }
    @include responsive.media-breakpoint-down(smxs) {
      display: none;
    }
  }

  .down-wrapper__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
  }

  .down-wrapper__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 12px;
    margin-bottom: 20px;

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }

  .input-radio-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 25px;

    @include responsive.media-breakpoint-down(xl) {
      margin-bottom: 20px;
      flex-direction: column;
    }

    ul {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;

      @include responsive.media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }

    li:not(:first-child) {
      margin-top: 12px;
    }

    li {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .white-circle, .blue-circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include responsive.media-breakpoint-down(xl) {
      width: 64px;
    }

    @include responsive.media-breakpoint-down(sm) {
      width: 36px;
    }
  }

  .white-circle {
    bottom: 0;
    @include responsive.media-breakpoint-down(xl) {
      bottom: -4px;
    }

    @include responsive.media-breakpoint-down(sm) {
      bottom: -11px;
    }
  }

  .blue-circle {
    top: 0;
    @include responsive.media-breakpoint-down(xl) {
      top: -4px;
    }

    @include responsive.media-breakpoint-down(sm) {
      top: -11px;
    }
  }

 /* Скрываем оригинальный radio button */
  input[type="radio"] {
    display: none;
  }

  /* Стилизуем кастомный кружок */
  input[type="radio"] + label {
    position: relative;
    padding-left: 30px; /* Размер кружка + отступ для текста */
    cursor: pointer;
    display: inline-block;
    line-height: 24px; /* Высота кружка */
  }

  /* Создаем кастомный кружок */
  input[type="radio"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #F3F7FB;

    @include responsive.media-breakpoint-down(sm) {
      width: 20px;
      height: 20px;
    }
  }

  /* Добавляем стили для активного (выбранного) кружка */
  input[type="radio"]:checked + label:before {
    background-color: #fff; /* Белый цвет фона для активного кружка */
    border-color: #3366cc; /* Цвет обводки для активного кружка */
    content: ''; /* Очищаем содержимое псевдоэлемента */
    z-index: 1;
    width: 10px;
    height: 10px;
    left: 7px;

    @include responsive.media-breakpoint-down(sm) {
      width: 8px;
      height: 8px;
      left: 6px;
    }
  }

  input[type="radio"]:checked + label {
    .circle {
      display: block;
    }
  }

  .circle {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: var(--blue-100);
    z-index: 0;
    border-radius: 50%;

    @include responsive.media-breakpoint-down(sm) {
      width: 20px;
      height: 20px;
    }
  }
}
