@use '../mixins/responsive';

.header {
  position: relative;
  z-index: 10;
  background-color: var(--white-100);
  &-nav {
    display: flex;
    gap: 32px;
    position: relative;
    @include responsive.media-breakpoint-down(xl) {
      gap: 24px;
    }

    @include responsive.media-breakpoint-down(elg) {
      display: none;
    }

    &_item {
      color: var(--black-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      transition: 0.3s;
      display: flex;
      align-items: center;
      gap: 4px;

      &:after {
        display: inline-block;
        width: 16px;
        height: 16px;
      }

      &:hover {
        color: var(--green-100);
      }

      &__select {
        &:hover {
          .header-nav_sub-list {
            opacity: 1;
            pointer-events: auto;
            z-index: 1;
          }
        }
      }
    }

    &_sub {
      &-list {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 100%;

        &_container {
          overflow: hidden;
          margin-top: 8px;
          padding: 4px 0;
          width: 160px;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          background: var(--white-100);
          box-shadow: 0 12px 40px 0 rgba(14, 15, 17, 0.05);
          transition: 0.3s;
        }
      }

      &_item {
        padding: 8px 16px 11px;

        &:hover {
          background: var(--Card-Background);
        }
      }
    }
  }

  &-contacts {
    display: flex;
    gap: 24px;
    position: relative;
  }

  &-contact {
    color: var(--black-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    transition: 0.3s;
    display: flex;
    gap: 4px;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 8px;

    &:before {
      display: block;
      width: 16px;
      height: 16px;
    }

    &__link:hover {
      color: var(--grey-100);
    }
  }

  &-logo {
    position: relative;
    height: 42px;


    &_desc {
      color: var(--grey-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
