@use '../mixins/responsive';

.header-burger {
  display: none;
  @include responsive.media-breakpoint-down(elg) {
    display: block;
  }
}

.burger-menu {
  z-index: 1;

  &_content {
    height: 100%;
    overflow: auto;
  }

  &.show {

  }

  &_container {
    width: 372px;
    background: var(--white-100);
    height: 100%;
    position: relative;
    z-index: 2;
    @include responsive.media-breakpoint-down(sm) {
      width: 300px;
    }
    @include responsive.media-breakpoint-down(smxs) {
      width: 100%;
    }
  }

  &_contacts {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include responsive.media-breakpoint-down(sm) {
      padding: 24px 20px;
      gap: 12px;
    }

    &_main-number {
      &__label {
        color: var(--grey-100, #8F959B);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        margin-bottom: 8px;
        span {
          border-radius: 8px;
          background: var(--green-100, #28906F);
          padding: 2px 6px;

          color: #FCFCFC;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 19.6px */
          margin-left: 4px;
        }
      }
      &__number {
        color: var(--black-100);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 28.8px */
        @include responsive.media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }
    }

    &_social {
      display: flex;
      gap: 8px;
    }

    &_btn {
      display: flex;
      justify-content: center;
      border-radius: 12px;
      background: var(--grey-10);
      padding: 14px;

      color: var(--black-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      &__call {
        width: 100%;
        flex-grow: 2;
      }
    }

    &_sub-contacts {

    }
  }

  &_nav {
    &__select {
      &.open {
        .burger-menu_nav_item__arrow {
          transform: rotate(180deg);
        }
      }
    }

    &_item {
      display: flex;
      justify-content: space-between;

      color: var(--black-100);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      padding: 24px;
      border-top: 1px solid #F3F7FB;
      @include responsive.media-breakpoint-down(sm) {
        padding: 16px 20px;
      }

      &__arrow {
        height: 20px;
        transform: rotate(0deg);
        transition: 0.3s;
      }
    }

    &_sub-list {
      height: 0;
      overflow: hidden;
      transition: 0.3s;
    }
  }

  &_submenu {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        display: flex;
        align-items: center;
        gap: 4px;

        color: var(--black-100);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */

        &:before {
          width: 24px;
          height: 24px;
        }
      }

      &__arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
}
