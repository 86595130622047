@use '../mixins/responsive';

.consultation-modal {

  &_wrapper {
    --modal-wrapper-width: 1020px;

    &__success {
      --modal-wrapper-width: 456px;
      @include responsive.media-breakpoint-down(elg) {
        --modal-wrapper-width: 100%;
      }
    }
  }

  .consultation-modal_container {
    --modal-container-width: 960px;

    &__success {
      --modal-container-width: 396px;
      --modal-midle-min-height: 388px;
      --modal-midle-padding: 16px;
      @include responsive.media-breakpoint-down(elg) {
        --modal-midle-padding: 32px 32px 64px;
        --modal-midle-min-height: 0;
      }
      @include responsive.media-breakpoint-down(smd) {
        --modal-midle-padding: 28px 16px 16px;
      }
    }
  }

  &_content {
    display: flex;
    gap: 24px;
    height: 100%;
    align-items: center;
    @include responsive.media-breakpoint-down(elg) {
      align-items: center;
    }
    @include responsive.media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      gap: 32px;
    }
  }

  &_banner {
    flex: 1 0 auto;
    width: 520px;
    height: 572px;
    padding: 32px 32px 16px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    @include responsive.media-breakpoint-down(lg) {
      width: 100%;
      height: 396px;
    }
    @include responsive.media-breakpoint-down(md) {
      height: max-content;
      padding: 24px;
    }
    @include responsive.media-breakpoint-down(smxs) {
      width: 100vw;
    }

    &_content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &_bg {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -1;
      background-color: var(--green-100);

      &__img {
        top: 11px;
        right: -29px;
        position: absolute;
        width: 288px;
        @include responsive.media-breakpoint-down(lg) {
          right: -3px;
          top: -30px;
        }
        @include responsive.media-breakpoint-down(smd) {
          right: -8px;
          top: 129px;
          width: 215px;
        }
      }

      &__img2 {
        max-width: 277px;
        top: 65px;
        right: 0;
        position: absolute;
        @include responsive.media-breakpoint-down(lg) {
          top: 18px;
          right: 21px;
        }
        @include responsive.media-breakpoint-down(smd) {
          right: 0;
          top: 134px;
          width: 215px;
        }
      }
    }

    &_content {
      @include responsive.media-breakpoint-down(smd) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &_title {
      color: var(--white-100);
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 16px;
      @include responsive.media-breakpoint-down(lg) {
        font-size: 32px;
        margin-bottom: 12px;
      }
      @include responsive.media-breakpoint-down(smd) {
        text-align: center;
      }
      @include responsive.media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }

    &_desc {
      color: var(--white-50);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 24px;
      max-width: 299px;
      @include responsive.media-breakpoint-down(lg) {
        max-width: 375px;
      }
      @include responsive.media-breakpoint-down(smd) {
        text-align: center;
      }
      @include responsive.media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }

  &_form {
    padding: 32px 12px 28px 0;
    @include responsive.media-breakpoint-down(elg) {
      padding: 0 12px 0 0;
      width: 100%;
    }
    @include responsive.media-breakpoint-down(lg) {
      padding: 0;
    }

    &_title {
      color: var(--black-100, #0E0F11);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
      margin-bottom: 24px;
      @include responsive.media-breakpoint-down(md) {
        margin-bottom: 32px;
      }
      @include responsive.media-breakpoint-down(sm) {
        font-size: 24px;
        margin-bottom: 24px;
      }

      span {
        color: var(--green-100);
      }

      &__sub {
        color: var(--grey-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: flex;
        width: max-content;
        align-items: center;
        margin: 0 auto 12px;

        @include responsive.media-breakpoint-down(lg) {
          display: none;
        }

        &:before {
          width: 18px;
          height: 18px;
          margin-right: 4px;
          display: flex;
        }
      }
    }

    &_input {
      margin-bottom: 12px;
      border-radius: 16px;
    }

    &_submit {
      margin-top: 12px;
      color: var(--white-100);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      padding: 22.5px 24px;
      width: 100%;
      border-radius: 16px;
      background: var(--blue-100);
      transition: 0.3s;
      @include responsive.media-breakpoint-down(md) {
        padding: 14px 24px;
        font-size: 14px;
        border-radius: 12px;
        line-height: 140%;
      }

      &:hover {
        background: #1146EF;
      }
    }

    &_checkbox {
      display: flex;
      gap: 8px;
      cursor: pointer;
      padding: 12px 0;
      @include responsive.media-breakpoint-down(sm) {
        padding: 4px 0;
        align-items: center;
      }

      &.error {
        --checkbox-border: var(--red-100)
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          & ~ .consultation-modal_form_checkbox__box {
            background: var(--green-100);
            border: 1px solid var(--green-100);
          }
        }
      }

      &__text {
        color: #8F959B;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @include responsive.media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }

      &__box {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        border: 1px solid var(--checkbox-border, #8F959B);
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border: 1px solid var(--checkbox-border, var(--blue-20));
        }

        &:before {
          width: 16px;
          height: 16px;
          content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5397 4.79652C12.7086 4.96946 12.7086 5.24985 12.5397 5.42279L6.89414 11.2038C6.72525 11.3767 6.45143 11.3767 6.28254 11.2038L3.45974 8.31328C3.29085 8.14034 3.29085 7.85995 3.45974 7.68701C3.62863 7.51407 3.90246 7.51407 4.07135 7.68701L6.58834 10.2644L11.9281 4.79652C12.097 4.62358 12.3708 4.62358 12.5397 4.79652Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7305 4.6102C13.0006 4.88675 13.0006 5.33256 12.7305 5.60911L7.08493 11.3901C6.81141 11.6702 6.36527 11.6702 6.09176 11.3901L3.26896 8.4996C2.99889 8.22305 2.99889 7.77724 3.26896 7.5007C3.54248 7.22062 3.98861 7.22062 4.26213 7.5007L6.58834 9.88269L11.7373 4.6102C12.0109 4.33013 12.457 4.33013 12.7305 4.6102ZM6.58834 10.2644L4.07135 7.68701C3.90246 7.51407 3.62863 7.51407 3.45974 7.68701C3.29085 7.85995 3.29085 8.14034 3.45974 8.31328L6.28254 11.2038C6.45143 11.3767 6.72525 11.3767 6.89414 11.2038L12.5397 5.42279C12.7086 5.24985 12.7086 4.96946 12.5397 4.79652C12.3708 4.62358 12.097 4.62358 11.9281 4.79652L6.58834 10.2644Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &_success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include responsive.media-breakpoint-down(md) {
      padding: 0 0 32px 0;
    }
    @include responsive.media-breakpoint-down(md) {
      padding: 0;
    }

    &_img {
      width: 100px;
      height: 100px;
      margin-bottom: 24px;
      @include responsive.media-breakpoint-down(elg) {
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
      }
      @include responsive.media-breakpoint-down(sm) {
        margin-bottom: 12px;
        width: 64px;
        height: 64px;
      }
    }

    .consultation-modal_form_title {
      margin-bottom: 16px;
      @include responsive.media-breakpoint-down(md) {
        max-width: 324px;
        margin-bottom: 12px;
      }
      @include responsive.media-breakpoint-down(sm) {
        max-width: 244px;
      }
    }

    &_desc {
      color: var(--black-100);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      @include responsive.media-breakpoint-down(md) {
        max-width: 312px;
      }
    }

    &_one-more {
      margin-top: 32px;
      padding: 22.5px 24px;
      height: max-content;
      font-size: 16px;
      line-height: 120%;
      width: max-content;
      @include responsive.media-breakpoint-down(elg) {
        margin-top: 20px;
      }
      @include responsive.media-breakpoint-down(md) {
        padding: 14px 24px;
        width: max-content;
        font-size: 14px;
        line-height: 140%;
      }
      @include responsive.media-breakpoint-down(sm) {
        margin-top: 12px;
      }
    }
  }

  &_achievements {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 288px;
    z-index: 1;
    @include responsive.media-breakpoint-down(lg) {
      flex-wrap: nowrap;
      max-width: 100%;
      gap: 24px;
    }
    @include responsive.media-breakpoint-down(smd) {
      align-self: flex-start;
      flex-direction: column;
    }
    @include responsive.media-breakpoint-down(smd) {
      gap: 12px;
    }
  }

  &_achievement {
    &_title {
      color: var(--white-100);
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.8px;
      margin-bottom: 4px;
      @include responsive.media-breakpoint-down(lg) {
        font-size: 32px;
        margin-bottom: 8px;
      }
      @include responsive.media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 120%;
      }
    }

    &_desc {
      color: var(--white-50);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    &__main {
      width: 100%;
      margin-bottom: 4px;
      @include responsive.media-breakpoint-down(lg) {
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  &_props {
    display: flex;
    gap: 4px;
    margin: 0 -16px;
    margin-top: auto;
    position: relative;
    align-items: flex-end;
    @include responsive.media-breakpoint-down(smd) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 32px -8px -8px;
      width: 100%;
    }
    @include responsive.media-breakpoint-down(sm) {
      width: auto;
    }

    &-card {
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.20);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 12px;
      height: 110px;
      z-index: 10;
      @include responsive.media-breakpoint-down(lg) {
        height: 106px;
        padding: 16px;
      }
      @include responsive.media-breakpoint-down(smd) {
        height: 100px;
        padding: 12px;
      }

      &_title {
        margin-top: auto;
        color: var(--white-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        @include responsive.media-breakpoint-down(smd) {
          font-size: 14px;
        }

        span {
          font-weight: 500;
          color: var(--white-50);
          font-size: 14px;
          font-style: normal;
          line-height: 120%;
        }
      }

      &__present {
        z-index: 0;
        position: absolute;
        border-radius: 70px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
        backdrop-filter: blur(16px);
        width: 211.179px;
        height: 223.87px;
        transform: rotate(-15deg);
        right: -75px;
        top: -133%;
        padding: 24px 20px 32px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @include responsive.media-breakpoint-down(lg) {
          display: none;
        }

        .consultation-modal_props-card_title {
          margin-top: 0;
          text-align: center;
          font-weight: 700;
        }

        &_img {
          position: absolute;
          transform: rotate(-15deg);
          right: -15px;
          top: -71%;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 999999;

          @include responsive.media-breakpoint-down(lg) {
            display: none;
          }

          .main-banner_present_img {
            width: 86.496px;
            height: 92.42px;
          }

          .main-banner_present_btn {
            font-size: 16.588px;
          }
        }
      }

      &__white {
        display: none;

        position: relative;
        height: 155px;
        width: 156px;

        flex-direction: column;
        justify-content: space-between;
        border-radius: 12px;
        background: var(--white-100);
        padding-top: 8px;
        order: 4;

        @include responsive.media-breakpoint-down(lg) {
          display: flex;
        }
        @include responsive.media-breakpoint-down(smd) {
          width: auto;
          height: 100px;
          order: 2;
        }

        .consultation-modal_props-card__ico {
          width: 44.923px;
          height: 48px;
          transform: rotate(-15deg);
          @include responsive.media-breakpoint-down(smd) {
            width: 40px;
            height: 40px;
          }
        }

        .consultation-modal_props-card_title {
          color: var(--black-100);

          span {
            font-weight: 600;
            font-size: 16px;
            color: var(--red-100);
            @include responsive.media-breakpoint-down(smd) {
              font-size: 14px;
            }
          }
        }
      }

      &:nth-child(1) {
        order: 1;
        @include responsive.media-breakpoint-down(smd) {
          order: 3;
        }
      }

      &:nth-child(2) {
        order: 2;
        @include responsive.media-breakpoint-down(smd) {
          order: 4;
        }
      }

      &:nth-child(3) {
        order: 3;
        @include responsive.media-breakpoint-down(smd) {
          order: 1;
        }
      }
    }
  }

}
