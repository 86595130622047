@use '../mixins/responsive';

.footer-swiper {
  margin-top: 48px;
  position: relative;
  overflow: hidden;

  @include responsive.media-breakpoint-down(elg) {
    margin-top: 40px;
  }

  @include responsive.media-breakpoint-down(sm) {
    margin-top: 24px;
  }

  .footer-swiper__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 20px;
    flex-wrap: wrap;

    @include responsive.media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  .footer-swiper__see-more {
    color: var(--white-100);
  }

  .swiper-slide {
    display: flex;
    align-items: flex-start;
    padding: 24px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    gap: 12px;

    @include responsive.media-breakpoint-down(lg) {
      width: 300px;
    }

    @include responsive.media-breakpoint-down(sm) {
      width: 254.4px;
    }
  }

  .icon-container {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      color: var(--white-100);
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
    }

    p {
      color: var(--grey-100);
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  .swiper-wrapper {
    margin-bottom: 24px;
  }

  .swiper-scrollbar {
    z-index: 1;
    width: 100%;
    height: 8px;
    background-color:rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    position: unset !important;
  }

  .swiper-scrollbar-drag {
    background-color: var(--white-100);
    border-radius: 10px;
  }
}
