@use '../mixins/responsive';

.reviews {
  .padding-top-bottom {
    padding-top: 40px;
    padding-bottom: 40px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 32px;
      padding-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__top-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: var(--white-50);
    z-index: 1;

    @include responsive.media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  h2 {
    margin-top: 24px;
    font-weight: 600;
    font-size: 56px;
    line-height: 56px;
    color: var(--white-100);
    z-index: 1;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 16px;
      font-size: 48px;
      line-height: 48px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 12px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 32px;
      line-height: 38.4px;
      text-align: center;
    }
  }

  &-wrapper {
    background-color: var(--green-100);
    position: relative;
    padding: 60px 36px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 40px;
    overflow: hidden;

    @include responsive.media-breakpoint-down(xl) {
      padding: 48px 32px 40px 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      padding: 40px 32px;
      border-radius: unset;
    }

    @include responsive.media-breakpoint-down(sm) {
      padding: 32px 16px;
    }
  }

  .bg-svg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 0;

    @include responsive.media-breakpoint-down(sm) {
      display: none;
    }
  }

  .bg-svg-mob {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    @include responsive.media-breakpoint-down(sm) {
      display: block;
    }
  }

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    border-radius: 24px;
    padding: 32px;
    background-color: var(--white-100);

    @include responsive.media-breakpoint-down(xl) {
      padding: 24px;
    }

    @include responsive.media-breakpoint-down(sm) {
      width: 300px;
    }

    @include responsive.media-breakpoint-down(smxs) {
      width: 100%;
    }

    &__top-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 48px;

      @include responsive.media-breakpoint-down(sm) {
        margin-bottom: 32px;
      }
    }

    &__img-person {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      strong {
        margin-top: 8px;
        font-weight: 600;
        color: var(--black-100);
        font-size: 14px;
        line-height: 19.6px;
      }

      span {
        font-weight: 400;
        color: var(--grey-100);
        font-size: 14px;
        line-height: 19.6px;
      }

      .icon-like {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: var(--white-100);
        border-radius: 50%;
        position: absolute;
        right: 11px;
        top: 0;
      }
    }

    .human-img {
      border-radius: 50%;
    }

    &__bottom-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;

      @include responsive.media-breakpoint-down(sm) {
        gap: 12px;
      }

      p {
        font-size: 20px;
        line-height: 28px;

        @include responsive.media-breakpoint-down(xl) {
          font-size: 16px;
          line-height: 22.4px;
        }
      }

      span {
        font-size: 16px;
        line-height: 22.4px;
        color: var(--grey-100);

        @include responsive.media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 19.6px;
        }
      }
    }
  }

  .is-video-review {
    position: absolute;
    top: -32px;
    left: 6%;
    background-color: var(--blue-100);
    height: 32px;
    padding-right: 12px;
    padding-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--white-100);

    svg {
      flex-shrink: 0;
    }
  }

  .swiper-wrapper {
    margin-bottom: 30px;

    @include responsive.media-breakpoint-down(xl) {
      margin-bottom: 32px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
  }

  .swiper-scrollbar {
    display: none;

    @include responsive.media-breakpoint-down(xl) {
      display: block;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color:rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      position: unset !important;
    }
  }

  .swiper-scrollbar-drag {
    background-color: var(--white-100);
    border-radius: 10px;
  }

  .arrows-right-left {
    position: absolute;
    right: 20px;
    bottom: 37%;
    z-index: 2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include responsive.media-breakpoint-down(xl) {
      display: none;
    }
  }

  .arrow {
    background-color: var(--white-100);
    box-shadow: 1px 8px 20px 0 rgba(12, 13, 16, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;

    &:nth-child(2) {
      transform: rotate(180deg);
    }

    &-left, &-right {
      transition: transform .3s;

      &:not(.swiper-button-disabled) {
        &:hover path {
          fill: var(--green-100)
        }
      }
    }
  }

  &__down-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 1;
    flex-wrap: wrap;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 32px;
      max-width: 350px;
      flex-direction: column-reverse;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-top: 40px;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-top: 32px;
    }

    li {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 0 16px;
      background-color: var(--white-10);
      border-radius: 1000px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;

      @include responsive.media-breakpoint-down(xl) {
        &:nth-child(2) {
          order: 1;
        }
      }

      @include responsive.media-breakpoint-down(sm) {
        font-size: 14px;
      }

      @include responsive.media-breakpoint-down(smxs) {
        height: auto;
        padding: 16px;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        font-size: 13px;
      }

      strong {
        color: var(--white-100);
      }

      p {
        color: var(--white-50);
      }
    }
  }

  .slider-container {
    margin-top: 48px;

    @include responsive.media-breakpoint-down(xl) {
      margin-top: 40px;
    }
  }
}
