@use '../mixins/responsive';

.footer {
  background-color: var(--black-100);
  position: relative;
  overflow: hidden;

  .bg-img {
    position: absolute;
    right: 0;
    top: 46px;
    z-index: 0;
    max-width: 598px;

    @include responsive.media-breakpoint-down(exxl) {
      right: 17px;
    }

    @include responsive.media-breakpoint-down(xxl) {
      display: none;
    }

    &-container {
      position: relative;
      width: 100%;
      margin: 0 auto;
      max-width: 1440px;
      pointer-events: none;
    }
  }

  .footer-left__title {
    color: var(--white-100);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;

    @include responsive.media-breakpoint-down(xl) {
      font-size: 18px;
      line-height: 25.2px;
    }

    @include responsive.media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.footer-middle {
  display: flex;
  align-items: center;
  padding-top: 48px;

  @include responsive.media-breakpoint-down(xl) {
    padding-top: 40px;
  }

  @include responsive.media-breakpoint-down(lg) {
    padding-top: 32px;
    flex-direction: column;
    align-items: flex-start;
  }

  @include responsive.media-breakpoint-down(sm) {
    padding-top: 24px;
  }

  .footer-left-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 360px;
    width: 100%;
    margin-right: 76px;

    @include responsive.media-breakpoint-down(xl) {
      gap: 12px;
      margin-right: 112px;
    }

    @include responsive.media-breakpoint-down(lg) {
      margin-right: 0;
      margin-bottom: 40px;
      max-width: 500px;
    }

    @include responsive.media-breakpoint-down(sm) {
      gap: 10px;
    }

    .footer-left__subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: var(--grey-100);
    }
  }

  .footer-right-container {
    max-width: 520px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    @include responsive.media-breakpoint-down(lg) {
      max-width: 680px;
      justify-content: space-between;
    }

    @include responsive.media-breakpoint-down(sm) {
      flex-wrap: wrap;
      gap: 20px;
      justify-content: unset;
    }

    ul {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      flex-direction: column;
      flex-wrap: wrap;

      @include responsive.media-breakpoint-down(sm) {
        width: calc(50%/2 + 75px);
      }

      li {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;

        a {
          color: var(--white-100);
          transition: 0.3s;

          &:hover {
            color: #FFFFFF99;
          }
        }
      }
    }
  }
}

.footer-middle-bottom {
  display: flex;
  margin-top: 48px;
  @include responsive.media-breakpoint-down(xl) {
    margin-top: 40px;
  }

  @include responsive.media-breakpoint-down(sm) {
    margin-top: 24px;
    flex-direction: column;
  }


  .footer-left-container {
    background-color: var(--green-100);
    border-radius: 20px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 521px;
    width: 100%;
    margin-right: 87px;

    @include responsive.media-breakpoint-down(xxl) {
      margin-right: 12px;
      max-width: 50%;
    }

    @include responsive.media-breakpoint-down(sm) {
      margin-right: 0;
      margin-top: 8px;
      max-width: 100%;
      order: 1;
    }

    .footer-left-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19.2px;
      color: var(--white-100);
    }

    ul {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;

      li {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 16px;
        width: calc((100% - (8px * 3)) / 4);

        @include responsive.media-breakpoint-down(xl) {
          width: calc(50% - 4px);
        }
      }
    }

    .count-stars-container {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      @include responsive.media-breakpoint-down(sm) {
        justify-content: space-between;
      }
    }

    .count-stars {
      margin-right: 12px;
      font-size: 18px;
      font-weight: 700;
      line-height: 25.2px;
      color: var(--white-100);
      display: flex;
      align-items: center;
      gap: 4px;

      @include responsive.media-breakpoint-down(sm) {
        margin-right: 0;
      }

      &:before {
        display: inline-block;
        width: 20px;
        height: 20px;
      }

      &.margin-right-unset {
        margin-right: 0;
      }
    }

    .number-of-reviews {
      color: rgba(255, 255, 255, 0.6);
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  .footer-right-relative {
    position: relative;
    max-width: 364px;
    width: 100%;
    background-color: var(--white-100);
    padding: 16px;
    border-radius: 20px;

    @include responsive.media-breakpoint-down(xxl) {
      max-width: 50%;
    }

    @include responsive.media-breakpoint-down(sm) {
      max-width: 100%;
    }

    .icon-img {
      position: absolute;
      right: -8px;
      top: -3px;
      z-index: 0;

      @include responsive.media-breakpoint-down(xxl) {
        display: none;
      }
    }
  }

  .footer-right-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span, p {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      z-index: 2;
      position: sticky;
    }

    span {
      color: var(--black-100);

      @include responsive.media-breakpoint-down(xl) {
        display: block;
        max-width: 260px;
        width: 100%;
      }

      @include responsive.media-breakpoint-down(sm) {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }

    p {
      color: var(--grey-100);
      margin-bottom: 12px;
    }

    a {
      position: sticky;
      z-index: 2;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .human-img {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      display: none;
      max-width: 228px;

      @include responsive.media-breakpoint-down(xl) {
        display: block;
      }

      @include responsive.media-breakpoint-down(elg) {
        top: 43px;
        width: 148px;
      }

      @include responsive.media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
